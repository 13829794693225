import { FC } from "react";

interface Props {
  slideIndex: number;
  setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
  slides: any[];
}

export const CompletedContractsIndicator: FC<Props> = ({
  slideIndex,
  slides,
  setSlideIndex,
}) => {
  return (
    <div className="completed_contracts_indicator">
      {slides.map((e, i) => (
        <div
          key={i}
          className={
            i == slideIndex
              ? "completed_contracts_radio-active"
              : "completed_contracts_radio"
          }
          onClick={() => {
            setSlideIndex(i);
          }}
        ></div>
      ))}
    </div>
  );
};
