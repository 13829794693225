import "./ServiceDescriptionPage.scss";

export const DrainWorks = () => {
  return (
    <div className="service_description_page_wrapper">
      <h1>ЛИВНЕВАЯ КАНАЛИЗАЦИЯ</h1>
      <div className="service_description_content">
        <img src="/assets/services/livnevaya.webp" alt="ЛИВНЕВАЯ КАНАЛИЗАЦИЯ" />
        <div className="service_description">
          <h2>
            Компания «АЯКС» выполняет проектирование систем ливневой канализации
            Geberit. Мы занимаемся производством адаптеров, что оптимизирует
            затраты клиентов на обустройство объектов. Также осуществляем
            поставку изделий известных брендов по доступным ценам.
          </h2>
          <h3>Монтаж систем ливневой канализации от профессионалов</h3>
          <p>
            Выполняем оптовые поставки продукции в Москве и Московской области.
            Изделия соответствуют российским стандартам качества. Ливневая
            канализация Geberit используется в самотечной и сифонной системах
            водостока, при обустройстве центрального пылеудаления, в
            промышленности. Проектирование систем ливневой канализации Geberit
            выполняют опытные сотрудники компании. Бригада мастеров готова в
            кратчайшие сроки приступить к монтажу. С заказчиками поддерживаем
            договорные отношения, предоставляем гарантию и обслуживание.
            Доставку нужного объема изделий выполняем собственным транспортом.
            ​​​​​​​Чтобы получить консультацию, позвоните по указанному на сайте
            номеру телефона, или заполните форму обратной связи.
          </p>
        </div>
      </div>
    </div>
  );
};
