import { useLocation } from "react-router";
import { Navigate } from "react-router";
import "./ServiceFormPage.scss";
import "../Page.scss";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../../config";

export const ServiceFormPage = () => {
  const url = useLocation();

  const path = url.pathname.split("/")[url.pathname.split("/").length - 1];

  console.log(path);

  const [personalDataAgree, setPersonalDataAgree] = useState<boolean>(false);

  const [isInputEmptyError, setIsInputEmptyError] = useState<boolean>(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const [showForm, setShowForm] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const nameInputRef = useRef<HTMLInputElement>(null);

  const phoneInputRef = useRef<HTMLInputElement>(null);

  const noteInputRef = useRef<HTMLTextAreaElement>(null);

  const subjectInputRef = useRef<HTMLInputElement>(null);

  const emailInputRef = useRef<HTMLInputElement>(null);

  const personalDataAgreementRef = useRef<HTMLInputElement>(null);

  const formRef = useRef<HTMLFormElement>(null);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const urlToHeadingDictionary = {
    "aerosol-extinguish": "Система аэрозольного пожаротушения",
    "gas-extinguish": "Система газового пожаротушения",
    "powder-extinguish": "Система порошкового пожаротушения",
    "water-spray-extinguish": "Система спринклерного пожаротушения",
    "foam-extinguish": "Система пенного пожаротушения",
    "thin-spray-extinguish": "Установки тушения тонкораспыленной водой",
    appz: "Система автоматической противопожарной защиты",
    aps: "Автоматическая пожарная сигнализация",
    vpv: "Внутренний противопожарный водопровод",
    soue: "Система оповещения и управления эвакуацией",
  };

  const getHeadingFromUrl = () => {
    return urlToHeadingDictionary[path as keyof typeof urlToHeadingDictionary];
  };

  const sendOrder = async () => {
    if (
      nameInputRef.current &&
      phoneInputRef.current &&
      (!nameInputRef.current.value || !phoneInputRef.current.value)
    ) {
      setIsInputEmptyError(true);
      return;
    }
    const data = new FormData();

    if (
      noteInputRef.current &&
      nameInputRef.current &&
      phoneInputRef.current &&
      emailInputRef.current &&
      subjectInputRef.current &&
      fileInputRef.current &&
      fileInputRef.current.files
    ) {
      console.log(fileInputRef.current.files[0]);
      data.append("file", fileInputRef.current.files[0]);
      data.append("name", nameInputRef.current.value);
      data.append("email", emailInputRef.current.value);
      data.append("subject", subjectInputRef.current?.value);
      data.append("phone", phoneInputRef.current.value);
      data.append("note", noteInputRef.current.value);
      data.append(
        "service",
        urlToHeadingDictionary[path as keyof typeof urlToHeadingDictionary]
      );
    }
    const res = await axios.post(serverUrl + "post-service-order", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    setShowSuccessMessage(true);
  };

  return (
    <>
      {getHeadingFromUrl() ? (
        <div className="wrapper">
          {showForm ? (
            <form action="" ref={formRef}>
              <svg
                onClick={() => setShowForm(false)}
                fill="#000000"
                height="36px"
                width="36px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 490 490"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                </g>
              </svg>
              <h3>Запросить коммерческое предложение</h3>
              <div className="personal_info_input_grid">
                <input
                  type="text"
                  className="personal_info_input"
                  placeholder="Имя(*)"
                  ref={nameInputRef}
                  style={isInputEmptyError ? { border: "1px solid red" } : {}}
                />
                <input
                  type="text"
                  className="personal_info_input"
                  placeholder="Телефон(*)"
                  ref={phoneInputRef}
                  style={isInputEmptyError ? { border: "1px solid red" } : {}}
                />
                <input
                  type="text"
                  className="personal_info_input"
                  placeholder="Тема"
                  ref={subjectInputRef}
                />
                <input
                  type="text"
                  className="personal_info_input"
                  placeholder="Эл. почта"
                  ref={emailInputRef}
                />
              </div>
              <div className="service_form_file_input_wrapper">
                <div className="input_title">Прикрепить ТЗ</div>
                <input
                  type="file"
                  className="file_input"
                  multiple={true}
                  ref={fileInputRef}
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onInput={() => {
                    if (fileInputRef.current?.files) {
                      setUploadedFiles(Array.from(fileInputRef.current.files));
                    }
                  }}
                />
                <button
                  className="file_input_button"
                  type="button"
                  onClick={(e) => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  загрузить
                </button>{" "}
                {fileInputRef.current?.files &&
                fileInputRef.current?.files?.length > 0 ? (
                  <div className="file_input_files">
                    {Array.from(uploadedFiles).map((e, i) => (
                      <div>
                        <img
                          src="/assets/placeholders/interactive.png"
                          alt=""
                        />
                        <p>{e.name}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <textarea
                ref={noteInputRef}
                className="service_form_note"
                placeholder="Дополнительно"
              ></textarea>
              <div className="agreement_input_wrapper">
                <input
                  type="checkbox"
                  name="personal_data_agreement"
                  id="1"
                  defaultChecked={false}
                  className="agreement_input"
                  ref={personalDataAgreementRef}
                  onInput={(e) => {
                    if (personalDataAgreementRef.current) {
                      setPersonalDataAgree(
                        personalDataAgreementRef.current?.checked
                      );
                    }
                  }}
                />
                <label htmlFor="personal_data_agreement">
                  Выражаю свое согласие с{" "}
                  <Link to="/privacy-policy">
                    политикой обработки персональных данных
                  </Link>
                </label>
              </div>
              <button
                className={
                  personalDataAgree
                    ? "service_form_button-active"
                    : "service_form_button-unactive"
                }
                type="button"
                onClick={() => {
                  if (personalDataAgree) {
                    sendOrder();
                  }
                }}
              >
                ОТПРАВИТЬ
              </button>
            </form>
          ) : (
            <></>
          )}
          {showSuccessMessage ? (
            <div
              className="order_success_message"
              onClick={() => setShowSuccessMessage(false)}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4 12.6111L8.92308 17.5L20 6.5"
                    stroke="#fd3c23"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
              <h3>Ваш заказ успешно отправлен</h3>
              <button>Ясно</button>
            </div>
          ) : (
            <></>
          )}
          <div className="page_wrapper">
            <div className="service_form_page_wrapper">
              <h1 className="page_heading">{getHeadingFromUrl()}</h1>
              <button
                className="order_button"
                onClick={() => setShowForm(true)}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="#fff"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V4C19 3.44772 18.5523 3 18 3ZM6.41421 7H9V4.41421L6.41421 7ZM7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H8C7.44772 14 7 13.5523 7 13ZM7 17C7 16.4477 7.44772 16 8 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H8C7.44772 18 7 17.5523 7 17Z"
                      fill="#fff"
                    ></path>{" "}
                  </g>
                </svg>
                <span>онлайн-заявка</span>
              </button>
              <div className="service_form_description">
                <div className="service_form_description_item">
                  <h2>Проектная документация</h2>
                  <h3>В состав проектной документации входит:</h3>
                  <ul className="service_form_description_list">
                    <li>титульные листы</li>
                    <li>содержание</li>
                    <li>
                      состав проектной документации (допускается не прикладывать
                      в каждый том)
                    </li>
                    <li>пояснительная записка</li>
                    <li>структурные и функциональные схемы</li>
                    <li>планы расположения оборудования</li>
                    <li>спецификация оборудования изделий и материалов</li>
                    <li>ведомость объемов работ (при необходимости)</li>
                    <li>задания разработчикам смежных систем</li>
                    <li>
                      прилагаемые материалы (технические условия на подключение
                      к существующим системам, на передачу извещений и др.)
                    </li>
                    <li>
                      сметная документация на строительно-монтажные работы (при
                      необходимости)
                    </li>
                    <li>акт обследования объекта (при необходимости).</li>
                  </ul>
                </div>
                <div className="service_form_description_item">
                  <h2>Рабочая документация</h2>
                  <h3>В состав проектной документации входит:</h3>
                  <ul className="service_form_description_list">
                    <li>титульные листы</li>
                    <li>общие данные</li>
                    <li>структурные и функциональные схемы</li>
                    <li>схемы подключения</li>
                    <li>
                      схемы монтажа оборудования как оконечного так и
                      центрального
                    </li>
                    <li>
                      планы расположения оборудования и кабельных трасс с
                      привязкой к смежным системам
                    </li>
                    <li>спецификация оборудования изделий и материалов</li>
                    <li>ведомость объемов работ (при необходимости)</li>
                    <li>задания разработчикам смежных систем</li>
                    <li>
                      прилагаемые материалы (технические условия на подключение
                      к существующим системам, на передачу извещения и др.)
                    </li>
                    <li>
                      сметная документация на строительно-монтажные работы (при
                      необходимости)
                    </li>
                    <li>акт обследования объекта (при необходимости)</li>
                    <li>
                      пояснительная записка (прилагаемый документ при
                      необходимости).
                    </li>
                  </ul>
                </div>
                <div className="service_form_description_item">
                  <h2>Дополнительная документация</h2>
                  <h3>
                    На крупных объектах, отдельными комплектами документации
                    разрабатывается:
                  </h3>
                  <ul className="service_form_description_list">
                    <li>
                      алгоритм работы систем противопожарной защиты объекта;
                    </li>
                    <li>
                      программа и методика испытаний систем противопожарной
                      защиты.
                    </li>
                  </ul>
                </div>
                <div className="service_form_description_item">
                  <h2>Сроки и цены</h2>
                  <p>
                    Стоимость проектирования систем противопожарной защиты
                    рассчитывается индивидуально под каждый объект, цена
                    проектирования зависит от следующих основных факторов:
                  </p>
                  <ul className="service_form_description_item">
                    <li>площадь проектируемого объекта;</li>
                    <li>
                      количество систем которыми должен оборудоваться объект в
                      соответствии с нормативными документами;
                    </li>
                    <li>необходимость обследования объекта;</li>
                    <li>расположения проектируемого объекта.</li>
                  </ul>
                </div>
                <div className="service_form_description_item">
                  <h2>Расчет стоимости работ</h2>
                  <p style={{ paddingBottom: "40px" }}>
                    Для определения стоимости и сроков разработки документации
                    системы противопожарной защиты Вашего объекта, направьте
                    запрос на электронную почту или по форме на сайте, наши
                    специалисты в сжатые сроки подготовят коммерческое
                    предложение.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="404" replace={true} />
      )}
    </>
  );
};
