import { Link } from "react-router-dom";

export const ForDesigners = () => {
  return (
    <div className="page_wrapper">
      <h1>проектировщикам</h1>
      <div style={{ margin: "0 auto", width: "100%", marginBottom: "80px" }}>
        <div className="text_wrapper">
          <b>
            В данном разделе Вы имеете возможность бесплатно скачать BIM-модель
            муфты спринклерной приварной для своей библиотеки
          </b>
          <div className="flex" style={{ marginTop: "30px" }}>
            <a
              href="/assets/downloadableFiles/mufta-splinkernogo-orositelia.rfa"
              className="page_link_wrapper centered"
            >
              <img
                src="/assets/placeholders/rfa.png"
                alt=""
                className="file_placeholder"
              />
              <span className="centered_span" style={{ marginLeft: "10px" }}>
                BIM-модель в формате RFA, 0,5МБ
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
