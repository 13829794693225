import "./Footer.scss";
import { FooterLinks } from "./FooterLinksSection";

export const Footer = () => {
  return (
    <footer className="footer_wrapper">
      <div className="footer_container">
        <div className="footer_widget_area">
          <aside className="footer_aside">
            <img
              src="https://ajax-group.ru/wp-content/uploads/2022/07/logo-90-2.png"
              alt="Логотип Аякс"
            />
            <div className="footer_links">
              <div className="footer_link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 405.333 405.333"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M373.333,266.88c-25.003,0-49.493-3.904-72.704-11.563c-11.328-3.904-24.192-0.896-31.637,6.699l-46.016,34.752    c-52.8-28.181-86.592-61.952-114.389-114.368l33.813-44.928c8.512-8.512,11.563-20.971,7.915-32.64    C142.592,81.472,138.667,56.96,138.667,32c0-17.643-14.357-32-32-32H32C14.357,0,0,14.357,0,32    c0,205.845,167.488,373.333,373.333,373.333c17.643,0,32-14.357,32-32V298.88C405.333,281.237,390.976,266.88,373.333,266.88z"
                        fill="#fa6755"
                      />
                    </g>
                  </g>
                </svg>
                <span>
                  Телефон: <a href="tel:+79251887777">+7 (925) 188-7777</a>
                </span>
              </div>
              <div className="footer_link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M10.688,95.156C80.958,154.667,204.26,259.365,240.5,292.01c4.865,4.406,10.083,6.646,15.5,6.646     c5.406,0,10.615-2.219,15.469-6.604c36.271-32.677,159.573-137.385,229.844-196.896c4.375-3.698,5.042-10.198,1.5-14.719     C494.625,69.99,482.417,64,469.333,64H42.667c-13.083,0-25.292,5.99-33.479,16.438C5.646,84.958,6.313,91.458,10.688,95.156z"
                          fill="#fa6755"
                        />
                        <path
                          d="M505.813,127.406c-3.781-1.76-8.229-1.146-11.375,1.542C416.51,195.01,317.052,279.688,285.76,307.885     c-17.563,15.854-41.938,15.854-59.542-0.021c-33.354-30.052-145.042-125-208.656-178.917c-3.167-2.688-7.625-3.281-11.375-1.542     C2.417,129.156,0,132.927,0,137.083v268.25C0,428.865,19.135,448,42.667,448h426.667C492.865,448,512,428.865,512,405.333     v-268.25C512,132.927,509.583,129.146,505.813,127.406z"
                          fill="#fa6755"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <span>
                  <a href="mailto:info@ajax.group">info@ajax.group</a>
                </span>
              </div>

              <div className="footer_link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 413.099 413.099"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M206.549,0L206.549,0c-82.6,0-149.3,66.7-149.3,149.3c0,28.8,9.2,56.3,22,78.899l97.3,168.399c6.1,11,18.4,16.5,30,16.5    c11.601,0,23.3-5.5,30-16.5l97.3-168.299c12.9-22.601,22-49.601,22-78.901C355.849,66.8,289.149,0,206.549,0z M206.549,193.4    c-30,0-54.5-24.5-54.5-54.5s24.5-54.5,54.5-54.5s54.5,24.5,54.5,54.5C261.049,169,236.549,193.4,206.549,193.4z"
                        fill="#fa6755"
                      />
                    </g>
                  </g>
                </svg>
                <span>Московская область, г. Мытищи, ул. Силикатная 39Ж</span>
              </div>
            </div>
          </aside>
          <FooterLinks
            url="about"
            linkItems={[
              {
                title: "Сертификаты и лицензии",
                url: "certificates",
                isNested: false,
              },
              {
                title: "Выполненные объекты",
                url: "completed-contracts",
                isNested: false,
              },
              {
                title: "Наши партнеры",
                url: "partners",
              },
              {
                title: "Новости",
                url: "news",
              },
            ]}
            sectionTitle="О компании"
          ></FooterLinks>
          <FooterLinks
            linkItems={[
              {
                title: "Проектирование",
                url: "services/design",
                isNested: false,
              },
              {
                title: "Монтажные работы АУПТ, ВПВ",
                url: "services/assembly-work/",
                isNested: false,
              },
              {
                title: "Электромонтажные работы ЭОМ, ЭС, СС",
                url: "services/electrical-work/",
              },
              {
                title: "Ливневая канализация",
                url: "services/drain",
              },
              {
                title: "Эксплуатация",
                url: "services/operation",
              },
            ]}
            sectionTitle="УСЛУГИ"
            url="services"
          />
          <FooterLinks
            sectionTitle="Продукция"
            url="merchandise"
            linkItems={[
              {
                title: "Муфта спринклерная",
                url: "shop",
                isNested: false,
              },
              {
                title: "Защита оросителя",
                url: "https://ajax-group.ru/shop/zashchita-orositelia/",
                isNested: false,
              },
              {
                title: "Важное: ",
                url: "https://ajax-group.ru/shop/zashchita-orositelia/",
                isNested: true,
                itemsNested: [
                  {
                    title: "— Проектировщикам",
                    url: "/for-designers",
                  },
                  {
                    title: "— Дилерам",
                    url: "/for-dealers",
                  },
                  {
                    title: "— Доставка",
                    url: "/shipment",
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
      <div className="footer_bottom">
        <div className="footer_media_links">
          <div className="footer_media_links_container">
            <a href="https://www.youtube.com/channel/UCfuhERzmX_NUAtRnt-DyVzQ">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 461.001 461.001"
                className="yt"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path>
                  </g>
                </g>
              </svg>
            </a>
            <a href="https://wa.me/79251887777">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 418.135 418.135"
                xmlSpace="preserve"
                className="whatsapp"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M198.929,0.242C88.5,5.5,1.356,97.466,1.691,208.02c0.102,33.672,8.231,65.454,22.571,93.536 L2.245,408.429c-1.191,5.781,4.023,10.843,9.766,9.483l104.723-24.811c26.905,13.402,57.125,21.143,89.108,21.631 c112.869,1.724,206.982-87.897,210.5-200.724C420.113,93.065,320.295-5.538,198.929,0.242z M323.886,322.197 c-30.669,30.669-71.446,47.559-114.818,47.559c-25.396,0-49.71-5.698-72.269-16.935l-14.584-7.265l-64.206,15.212l13.515-65.607 l-7.185-14.07c-11.711-22.935-17.649-47.736-17.649-73.713c0-43.373,16.89-84.149,47.559-114.819 c30.395-30.395,71.837-47.56,114.822-47.56C252.443,45,293.218,61.89,323.887,92.558c30.669,30.669,47.559,71.445,47.56,114.817 C371.446,250.361,354.281,291.803,323.886,322.197z"></path>
                    <path d="M309.712,252.351l-40.169-11.534c-5.281-1.516-10.968-0.018-14.816,3.903l-9.823,10.008 c-4.142,4.22-10.427,5.576-15.909,3.358c-19.002-7.69-58.974-43.23-69.182-61.007c-2.945-5.128-2.458-11.539,1.158-16.218 l8.576-11.095c3.36-4.347,4.069-10.185,1.847-15.21l-16.9-38.223c-4.048-9.155-15.747-11.82-23.39-5.356 c-11.211,9.482-24.513,23.891-26.13,39.854c-2.851,28.144,9.219,63.622,54.862,106.222c52.73,49.215,94.956,55.717,122.449,49.057 c15.594-3.777,28.056-18.919,35.921-31.317C323.568,266.34,319.334,255.114,309.712,252.351z"></path>
                  </g>
                </g>
              </svg>
            </a>
            <a href="https://t.me/AJAX_ASTRA">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 50 50"
                className="tg"
              >
                <path d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445	c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758	c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125	c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077	C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="site_info">
          <span>© 2013 - 2023 ООО «АЯКС» / ООО «АСТРА».</span>
          <span> Все права защищены.</span>
        </div>
      </div>
    </footer>
  );
};
