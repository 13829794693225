import "./ServiceDescriptionPage.scss";

export const ElectricalWorks = () => {
  return (
    <div className="service_description_page_wrapper">
      <h1>ЭЛЕКТРОМОНТАЖНЫЕ РАБОТЫ ЭОМ, ЭС, СС</h1>
      <div className="service_description_content">
        <img
          src="/assets/services/elektromontazhnye-raboty-1.jpg"
          alt="ЭЛЕКТРОМОНТАЖНЫЕ РАБОТЫ ЭОМ, ЭС, СС"
        />
        <div className="service_description">
          <h2>
            ООО «АЯКС» успешно действует в качестве подрядчика по выполнению
            электромонтажных работ и работ по монтажу слаботочных систем.
          </h2>
          <p>
            Компания работает с предприятиями различного масштаба, предоставляя
            услуги в Москве, Московской области, регионах. Взаимодействуем с
            Инвесторами, Заказчиками, Генеральными подрядчиками. Производим
            работы в новых и существующих электроустановках. Выполняем полный
            комплекс работ, от получения технических условий на подключение до
            оформления Акта осмотра энергопринимающей установки
            (Акта-допуска).Осуществляем подбор, поставку и монтаж необходимого
            оборудования и материалов. Отдельное направление деятельности —
            оптимизация проектных решений с целью удешевления строительства без
            ухудшения эксплуатационных характеристик. В рамках оптимизации
            оцениваются принятые технические и схемные решения, качество и
            стоимость оборудования, выдаются рекомендации для уменьшения
            стоимости и сроков поставки оборудования и монтажных работ.
          </p>
        </div>
      </div>
    </div>
  );
};
