import { FC, useState } from "react";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { MerchandiseItemInfo } from "../../../types";
import { RootState } from "../../../store/store";
import { Link } from "react-router-dom";

interface CartItemInfo extends MerchandiseItemInfo {
  cartItemsCount: number;
}

interface Props {
  info?: CartItemInfo;
}

export const MerсhandiseCardList: FC<Props> = ({ info }) => {
  const { setCartState } = useActions();

  const cartItems = useSelector(
    (state: RootState) => state.cartReducer.itemsArray
  );

  const addItemToCart = () => {
    /* setCartItems(info as MerchandiseItemInfo); */
    console.log(cartItems);
    if (cartItems.find((e) => e.item_key === info?.item_key)) {
      const foundItemIndex = cartItems.findIndex(
        (e) => e.item_key === info?.item_key
      );
      setCartState(
        cartItems.map((e, i) => {
          if (i == foundItemIndex) {
            return { ...e, cartItemsCount: e.cartItemsCount + 1 };
          }
          return e;
        })
      );
    } else {
      setCartState([
        ...cartItems,
        { ...(info as CartItemInfo), cartItemsCount: 1 },
      ]);
    }
  };

  const removeItemFromCart = () => {
    if (
      cartItems.find((e) => e.item_key === info?.item_key) &&
      cartItems.find((e) => e.item_key === info?.item_key)!.cartItemsCount > 1
    ) {
      const foundItemIndex = cartItems.findIndex(
        (e) => e.item_key === info?.item_key
      );
      setCartState(
        cartItems.map((e, i) => {
          if (i == foundItemIndex) {
            return { ...e, cartItemsCount: e.cartItemsCount - 1 };
          }
          return e;
        })
      );
    } else {
      setCartState(cartItems.filter((e) => e.item_key != info?.item_key));
    }
  };

  return (
    <div className="merchandise_card">
      {info ? (
        <>
          <div className="img_container">
            <img
              src={`/assets/merchandise/${info.item_img}`}
              alt="Изображение товара"
            />
          </div>
          <div className="card_content">
            <div className="card_left">
              {localStorage.getItem("token") ? (
                <Link to={`/shop/${info.item_key}/admin`}>
                  <p className="card_title">{info.item_title}</p>
                </Link>
              ) : (
                <Link to={`/shop/${info.item_key}`}>
                  <p className="card_title">{info.item_title}</p>
                </Link>
              )}
              <div className="card_features">
                <p>
                  <span>Диаметр:</span>Ду{info.item_diameter}
                </p>
                <p>
                  <span>Резьба:</span>
                  {info.item_thread}"
                </p>
                <p>
                  <span>Проточка:</span>
                  {info.item_groove ? "да" : "нет"}
                </p>
                <p>
                  <span>Длина изделия</span>
                  {info.item_length}мм
                </p>
              </div>
            </div>

            <div className="card_right">
              <span className="card_cost">₽{info.item_cost}</span>{" "}
              {cartItems.some((e) => e.item_key === info?.item_key) ? (
                <div className="card_controls_wrapper">
                  <div className="card_controls">
                    <div
                      className="card_control_button"
                      onClick={addItemToCart}
                    >
                      <span>+</span>
                    </div>
                    <input
                      className="card_control_count"
                      value={
                        cartItems.find((e) => e.item_key === info?.item_key)
                          ?.cartItemsCount
                      }
                      onChange={(e) => {
                        const foundItemIndex = cartItems.findIndex(
                          (item) => item.item_key === info?.item_key
                        );
                        console.log(e.target.value);
                        setCartState(
                          cartItems.map((item, i) => {
                            if (i == foundItemIndex) {
                              return {
                                ...item,
                                cartItemsCount: parseInt(e.target.value) || 0,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    ></input>
                    <div
                      className="card_control_button"
                      onClick={removeItemFromCart}
                    >
                      <span>-</span>
                    </div>
                  </div>
                </div>
              ) : (
                <button className="btn-grad" onClick={addItemToCart}>
                  Купить
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      )}
    </div>
  );
};
