import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoginState {
  isLoginSuccessful: boolean;
}

const initialState: LoginState = {
  isLoginSuccessful: false,
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    setLoginState(
      state,
      action: PayloadAction<LoginState["isLoginSuccessful"]>
    ) {
      state.isLoginSuccessful = action.payload;
    },
  },
});

export const loginSliceActions = loginSlice.actions;

export const loginReducer = loginSlice.reducer;
