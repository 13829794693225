import { configureStore } from "@reduxjs/toolkit";
import { cartReducer } from "./reducers/cartSlice";
import { loginReducer } from "./reducers/loginSlice";
import { editMenuReducer } from "./reducers/editMenuSlice";
export const store = configureStore({
  reducer: { cartReducer, editMenuReducer, loginReducer },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
