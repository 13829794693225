import { Carousel } from "../Carousel/Carousel";
import { MerhandiseCards } from "./MerchandiseCards/MerchandiseCards";
import "./Home.scss";
import { Services } from "./Services/Services";
import { Advantages } from "./Advantages/Advantages";
import { CompletedContracts } from "./CompletedContracts/Carousel/CompletedContracts";
import { Partners } from "./Partners/Partners";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div className="home_wrapper">
      <Helmet>
        <title>
          ООО «АЯКС» | Инженерия и пожарная безопасность в Москве и Московской
          области
        </title>
        <meta
          name="description"
          content="Производитель муфта спринклерная приварная, сертифицированный продукт! Наличие на складе! Бесплатная доставка по Москве и МО. Звоните! Скидки оптовикам. Индивидуальный заказ, в короткие сроки!"
        />
      </Helmet>
      <Carousel></Carousel>
      <Services></Services>
      <MerhandiseCards />
      <Advantages></Advantages>
      <CompletedContracts></CompletedContracts>
      <Partners></Partners>
    </div>
  );
};
export default Home;
