import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { MerchandiseItemInfo } from "../../types";

interface EditMenuState {
  url: string;
  propertiesArray: {
    propertyName: string;
    title: string;
    initialValue: any;
    type: "checkbox" | "text" | "textarea";
    dataType: "string" | "number";
    notEditable?: boolean;
  }[];
}

const initialState: EditMenuState = {
  url: "",
  propertiesArray: {} as any,
};

const editMenu = createSlice({
  name: "editMenu",
  initialState,
  reducers: {
    setEditMenuState(state, action: PayloadAction<EditMenuState>) {
      state.propertiesArray = action.payload.propertiesArray;
      state.url = action.payload.url;
    },
  },
});

export const editMenuActions = editMenu.actions;

export const editMenuReducer = editMenu.reducer;
