import { ArrowButton } from "../Header/NavigationFull/ArrowButton";
import "./Burger.scss";
import { FC } from "react";
import BurgerSection from "./BurgerSection";
import { Link } from "react-router-dom";

interface Props {
  burgerMenuState: "idle" | "active" | "unactive";
  setIsBurgerMenuActive: React.Dispatch<
    React.SetStateAction<"idle" | "active" | "unactive">
  >;
}

const Burger: FC<Props> = ({ setIsBurgerMenuActive, burgerMenuState }) => {
  return (
    <>
      {burgerMenuState != "idle" ? (
        <div
          className={
            burgerMenuState == "active"
              ? "burger_menu-active"
              : "burger_menu-unactive"
          }
        >
          <div className="burger_menu_top">
            <h3>Меню</h3>
            <svg
              onClick={() => setIsBurgerMenuActive("unactive")}
              className="burger_menu_close-btn"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
            </svg>
          </div>

          <div className="burger_menu_links">
            <Link to={"/"}>
              <BurgerSection title="Главная" url="/" />
            </Link>
            <BurgerSection
              url="about"
              links={[
                {
                  title: "Сертификаты и лицензии",
                  url: "certificates",
                },
                {
                  title: "Выполненные объекты",
                  url: "completed-contracts",
                },
                {
                  title: "Наши партнеры",
                  url: "partners",
                },
                {
                  title: "Новости",
                  url: "news",
                },
              ]}
              title="О компании"
            />
            <BurgerSection
              title="Услуги"
              url="services"
              links={[
                {
                  title: "Проектирование",
                  url: "services/design/",
                  isNested: true,
                  itemsNested: [
                    {
                      title: "АУПТ",
                      url: "services/design/aupt",
                    },
                    {
                      title: "ВПВ",
                      url: "services/design/vpv",
                    },
                    {
                      title: "АПС",
                      url: "services/design/aps",
                    },
                    {
                      title: "СОУЭ",
                      url: "services/design/soue",
                    },
                    {
                      title: "АППЗ",
                      url: "services/design/appz",
                    },
                  ],
                },

                {
                  title: "Монтажные работы АУПТ, ВПВ",
                  url: "services/assembly-work/",
                  isNested: false,
                },
                {
                  title: "Электромонтажные работы ЭОМ, ЭС, СС",
                  url: "services/electrical-work/",
                },
                {
                  title: "Ливневая канализация",
                  url: "services/drain",
                },
                {
                  title: "Эксплуатация",
                  url: "services/operation",
                },
              ]}
            ></BurgerSection>
            <BurgerSection
              title="Продукция"
              url="merchandise/"
              links={[
                {
                  title: "Муфта спринклерная",
                  url: "shop",
                  isNested: false,
                },
                {
                  title: "Защита оросителя",
                  url: "https://ajax-group.ru/shop/zashchita-orositelia/",
                  isNested: false,
                },
              ]}
            ></BurgerSection>
            <BurgerSection
              title="Важное"
              links={[
                {
                  title: "Проектировщикам",
                  url: "/for-designers",
                },
                {
                  title: "Дилерам",
                  url: "/for-dealers",
                },
                {
                  title: "Доставка",
                  url: "/shipment",
                },
              ]}
            ></BurgerSection>
            <ul>
              <span className="burger_menu_link">Контакты</span>
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Burger;
