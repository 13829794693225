import { FC } from "react";
import { INewsItem } from "../../../types";

interface Props {
  newsItem: INewsItem;
}

export const NewsItem: FC<Props> = ({ newsItem }) => {
  const getDate = (dateObj: string) => {
    let objectDate = new Date(dateObj);

    let day = objectDate.getDate();
    console.log(day);

    let month = objectDate.getMonth();
    console.log(month + 1);

    let year = objectDate.getFullYear();

    return `${day < 10 ? `0${day}` : day}.${
      month < 9 ? `0${month + 1}` : month + 1
    }.${year}`;
  };
  return (
    <div className="news_item">
      <div
        className="img_wrapper"
        style={{ backgroundImage: `url(/assets/news/${newsItem.img})` }}
      ></div>
      <div>
        <span>{getDate(newsItem.timestamp)}</span>
        <h2>{newsItem.title}</h2>
        <p>{newsItem.content}</p>
      </div>
    </div>
  );
};
