import "./ForDealers.scss";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../../config";

export const ForDealers = () => {
  return (
    <div className="page_wrapper">
      <h1>Дилерам</h1>
      <div style={{ height: "400px" }} className="text_wrapper">
        Если Вы хотите стать нашим дилером - просим Вас направить Ваши контакты
        и реквизиты на почту{" "}
        <a href="mailto:info@ajax.group">info@ajax.group</a>
      </div>
    </div>
  );
};
