import { serverUrl } from "../../../config";

import useFetch from "../../../hooks/useFetch";

import "./CompletedContractsPage.scss";

import { useLocation } from "react-router";

import { Contract } from "../../../types";
import { Link } from "react-router-dom";

export const CompletedContractsPage = () => {
  const { data, error, state } = useFetch<Contract[]>(`${serverUrl}contracts/`);

  console.log(data);

  return (
    <div className="completed_contracts_page_wrapper">
      <h1>Выполненные объекты</h1>
      <div className="completed_contracts">
        {data ? (
          data.map((e, i) => (
            <div className="contracts_item">
              <Link to={e.url}>
                <div
                  className="link_background"
                  style={{
                    backgroundImage: `url(${`/assets/banners/${e.banner_img}`})`,
                  }}
                >
                  <div className="item_title">
                    <Link to={e.url}>{e.title}</Link>
                  </div>
                  {/* <img src={e.banner_img} alt={e.title} /> */}
                </div>
              </Link>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
