import { useState } from "react";
import "./News.scss";
import useFetch from "../../../hooks/useFetch";
import { INewsItem } from "../../../types";
import { NewsItem } from "./NewsItem";
import { serverUrl } from "../../../config";

export const News = () => {
  const { data, error } = useFetch<INewsItem[]>(serverUrl + "news");

  console.log(data);
  return (
    <div className="news_wrapper">
      <h1>новости</h1>
      <div className="news_content">
        {data ? data.map((e, i) => <NewsItem newsItem={e} key={i} />) : <></>}
      </div>
    </div>
  );
};
