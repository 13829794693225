import { Helmet } from "react-helmet";
import "../Page.scss";
import "./About.scss";
import "../../Home/Advantages/Advantages.scss";
import { AdvantagesCard } from "../../Home/Advantages/AdvantagesCard";

export const About = () => {
  return (
    <div className="page_wrapper">
      <Helmet>
        <title>О компании | ООО «АЯКС»</title>
        <meta
          name="description"
          content="Компания «АЯКС» — это динамично, стабильно развивающееся предприятие, работающее в области инженерии и пожарной безопасности."
        />
      </Helmet>
      <h1>О Компании</h1>
      <div className="content_wrapper">
        <h2>КТО МЫ?</h2>
        <div className="underline_border"></div>
        <div className="description">
          <h3>Компания «АЯКС»</h3>
          <p>
            Компания «АЯКС» — это динамично, стабильно развивающееся
            предприятие, работающее в области инженерии и пожарной безопасности.
            ​​​​​​​Наша компания выполняет разработку проектных решений,
            поставку материалов и оборудования, изготовление трубных заготовок
            по чертежам заказчика, монтажные работы, гарантийное и пост
            гарантийное обслуживание. Компания имеет собственную
            производственную базу на которой налажен бесперебойный выпуск
            трубной заготовки для ускоренного монтажа инженерных систем с
            помощью бессварных (грувлочных) соединений. Мы рады работать с Вами.
            Главное для нас – безопасность клиентов и объектов, которые выбрали
            нас и которые нам доверяют.
          </p>
        </div>
        <div className="advantages_wrapper">
          <div className="advantages_container">
            <AdvantagesCard
              incrementableValueMax={10}
              description="ЛЕТ ОПЫТ РАБОТЫ"
            ></AdvantagesCard>
            <AdvantagesCard
              incrementableValueMax={50}
              description="ВЫСОКОКВАЛИФИЦИРОВАННЫХ СОТРУДНИКОВ"
            ></AdvantagesCard>
            <AdvantagesCard
              incrementableValueMax={300}
              description="БОЛЕЕ 300 ВЫПОЛНЕННЫХ ОБЪЕКТОВ"
            ></AdvantagesCard>
            <AdvantagesCard
              incrementableValueMax={365}
              description="РАБОЧИХ ДНЕЙ"
            ></AdvantagesCard>
          </div>
        </div>
        <div className="company_info_wrapper">
          <div className="company_info">
            <h3>Реквизиты</h3>
            <p>
              <b>Название фирмы:</b> ООО «АЯКС»
            </p>
            <p>
              <b>Юр. адрес:</b> 123290, г. Москва, тупик 1-й Магистральный, д.
              11, стр. 1, эт/п/к/оф 1/II/1/8Е
            </p>
            <p>
              <b>Почтовый адрес:</b> 123290, г. Москва, тупик 1-й Магистральный,
              д. 11, стр. 1, эт/п/к/оф 1/II/1/8Е
            </p>
            <p>
              <b>ИНН:</b> 7714455937
            </p>
            <p>
              <b>КПП:</b> 771401001
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
