import { Helmet } from "react-helmet";
import "./MerchandisePage.scss";
import { Link } from "react-router-dom";

export const MerchandisePage = () => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="merchandise_page_wrapper">
        <h1>продукция</h1>
        <div
          className="merchandise_page_banner"
          style={{ backgroundImage: 'url("/assets/merchandisePage/bg.jpg")' }}
        >
          <h2>
            Муфта спринклерная
            <br />
            приварная
          </h2>
          <h3>АСТРА</h3>
          <h4>Новинка в системах пожаротушения </h4>
        </div>
        <div className="merchandise_page_links">
          <div className="merchandise_page_link">
            <Link to="/shop">
              <b>МУФТА СПРИНКЛЕРНАЯ ПРИВАРНАЯ</b>
            </Link>
          </div>
          <div className="merchandise_page_link">
            <Link to="irrigation-protection">
              <b>ЗАЩИТА ОРОСИТЕЛЯ</b>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
