import "./ServiceDescriptionPage.scss";

export const AssemblyWorks = () => {
  return (
    <div className="service_description_page_wrapper">
      <h1>МОНТАЖНЫЕ РАБОТЫ АУПТ, ВПВ</h1>
      <div className="service_description_content">
        <img
          src="/assets/services/pozh.webp"
          alt="МОНТАЖНЫЕ РАБОТЫ АУПТ, ВПВ"
        />
        <div className="service_description">
          <h2>
            «АЯКС» обустраивает системы пожаротушения – выполняет
            проектирование, поставку оборудования и монтаж. Помогаем с
            оформлением нормативно-правовой документации. После завершения работ
            проводим инструктаж.
          </h2>
          <p>
            Компания работает с предприятиями различного масштаба, предоставляем
            услуги в Москве и Московской области. Наши специалисты
            взаимодействуют с лицами, занимающими должность ответственного за
            водяное пожаротушение на объекте. Помогаем приобрести в нужном
            количестве пожарные шкафы, узел управления, пожарные насосы и прочие
            элементы. В ассортименте также есть трубная заготовка, грувлок и
            накатка под грувлок. Для получения консультации или заказа услуг
            можно оставить заявку или связаться с менеджером по указанному
            номеру телефона. При обустройстве используем специализированное
            оборудование собственного производства. Поэтому цены на услуги в
            «АЯКС» наиболее выгодные на рынке. Наши установки соответствуют
            российским стандартам качества и FM-Global.
          </p>
        </div>
      </div>
    </div>
  );
};
