import { FC, useRef, useState } from "react";
import { FullMerchandiseItemInfo } from "../../../types";
import axios from "axios";
import { serverUrl } from "../../../config";
import { Helmet } from "react-helmet";

interface Props {
  titlesArray: string[];
  info: FullMerchandiseItemInfo;
}

export const MerchandiseItemInfo: FC<Props> = ({ titlesArray, info }) => {
  const [selectedItemTitle, setSelectedItemTitle] = useState(titlesArray[0]);
  const [hoveredStarValue, setHoveredStarValue] = useState(-1);
  const [usernameValue, setUsernameValue] = useState("");
  const [userEmailValue, setUserEmailValue] = useState("");
  const reviewFieldRef = useRef<HTMLTextAreaElement>(null); //у textarea нет ивента onChange :(
  const [selectedStarValue, setSelectedStarValue] = useState<null | number>(
    null
  );

  console.log(info.item_description);

  const getDate = (dateObj: string) => {
    let objectDate = new Date(dateObj);

    let day = objectDate.getDate();
    console.log(day);

    let month = objectDate.getMonth();
    console.log(month + 1);

    let year = objectDate.getFullYear();

    return `${day < 10 ? `0${day}` : day}.${month + 1}.${year}`;
  };

  const postReview = async (e: React.FormEvent<HTMLFormElement>) => {
    if (selectedStarValue) {
      const now = new Date();
      const requestBody: FullMerchandiseItemInfo["item_reviews"]["reviews"][0] =
        {
          text: reviewFieldRef.current?.value || "",
          rating: selectedStarValue,
          person_name: usernameValue || "Аноним",
          person_email: userEmailValue,
          timestamp: now.toISOString(),
        };
      const res = await axios.post(
        `${serverUrl}reviews/item/${info.item_key}`,
        requestBody,
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(res);
    }
  };

  return (
    <div className="merchandise_page_info">
      <Helmet>
        <title>{info.item_title + " | ООО «АЯКС»"}</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div className="info_navigation">
        {titlesArray.map((e, i) => (
          <div
            className={
              e == selectedItemTitle
                ? "navigation_heading_wrapper active"
                : "navigation_heading_wrapper"
            }
            onClick={() => setSelectedItemTitle(e)}
          >
            <h3>{e.toUpperCase()}</h3>
            <div className="heading_underline"></div>
          </div>
        ))}
      </div>
      {selectedItemTitle.toLowerCase() == "характеристики" ? (
        <div className="info_content">
          <table className="info_features">
            <tbody>
              <tr>
                <th>Диаметр</th>
                <td>Ду{info.item_diameter}</td>
              </tr>
              <tr>
                <th>Резьба</th>
                <td>{info.item_thread}"</td>
              </tr>
              <tr>
                <th>Проточка</th>
                <td>{info.item_groove ? "да" : "нет"}</td>
              </tr>
              <tr>
                <th>Вес упаковки</th>
                <td>{info.item_package_weight} кг</td>
              </tr>
              <tr>
                <th>Длина изделия</th>
                <td>{info.item_length} мм</td>
              </tr>
              <tr>
                <th>Вес</th>
                <td>{info.item_weight} гр</td>
              </tr>
              <tr>
                <th>Кол-во в упаковке</th>
                <td>{info.items_per_package} шт</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : selectedItemTitle.toLowerCase() == "описание" ? (
        <div className="info_content">
          <div className="info_description" style={{ whiteSpace: "pre-line" }}>
            {" "}
            <p>{info.item_description}</p>
          </div>
          <div className="info_blueprint">
            <img src={info.item_blueprint} alt="" />
          </div>
          <div className="info_features_table_container">
            <table className="product_table">
              <thead>
                <tr>
                  <th rowSpan={4} style={{ width: "100px" }}>
                    <strong>№&nbsp; п/п</strong>
                  </th>
                  <th rowSpan={4} style={{ width: "370px" }}>
                    <strong>Артикул</strong>
                  </th>
                  <th rowSpan={4} style={{ width: "150px" }}>
                    <strong>Условный проход, Ду</strong>
                  </th>
                  <th rowSpan={4}>
                    <strong>G, дюймы</strong>
                  </th>
                  <th colSpan={2}>
                    <strong>Длина, мм </strong>
                  </th>
                  <th colSpan={2}>
                    <strong>Толщина стенки, мм</strong>
                  </th>
                  <th rowSpan={3}>
                    <strong>Диаметр выборки, мм</strong>
                  </th>
                  <th rowSpan={2}>
                    <strong>Масса, кг</strong>
                  </th>
                  <th rowSpan={4} style={{ width: "320px" }}>
                    <strong>Резьба</strong>
                  </th>
                  <th rowSpan={4}>
                    <strong>Фаска внутренняя, со стороны резьбы</strong>
                  </th>
                </tr>
                <tr>
                  <th>
                    <strong>L</strong>
                  </th>
                  <th>
                    <strong>L2</strong>
                  </th>
                  <th>
                    <strong>S</strong>
                  </th>
                  <th>
                    <strong>S2</strong>
                  </th>
                </tr>
                <tr>
                  <th colSpan={4}>
                    <strong>Допустимо отклонение +/- </strong>
                  </th>
                  <th>
                    <strong>Допустимо отклонение +/- </strong>
                  </th>
                </tr>
                <tr>
                  <th>
                    <strong>
                      {info?.item_properties_table?.deviation?.l}мм
                    </strong>
                  </th>
                  <th>
                    <strong>
                      {info?.item_properties_table?.deviation?.l2}мм
                    </strong>
                  </th>
                  <th>
                    <strong>
                      {info?.item_properties_table?.deviation?.s}%
                    </strong>
                  </th>
                  <th>
                    <strong>
                      {info?.item_properties_table?.deviation.s2}%
                    </strong>
                  </th>
                  <th>
                    <strong>D</strong>
                  </th>
                  <th>{info?.item_properties_table?.deviation?.m}%</th>
                </tr>
              </thead>
              <tbody>
                {info?.item_properties_table?.rows.map((e, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{e.vendor_code}</td>
                    <td>{e.nominal_diameter}</td>
                    <td>{e.groove_diameter}</td>
                    <td>{e.l_length}</td>
                    <td>{e.l2_length}</td>
                    <td>{e.s_wall_thickness}</td>
                    <td>{e.s2_wall_thickness}</td>
                    <td>{e.sampling_diameter}</td>
                    <td>{e.weight}</td>
                    <td>{e.thread}</td>
                    <td>{e.inner_chamfer}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : selectedItemTitle.toLowerCase() == "видео" ? (
        <div className="info_content">
          <div className="info_videos_wrapper">
            <h1>ВИДЕО ИСПЫТАНИЙ МУФТЫ АСТРА</h1>
            <div className="info_heading_underline"></div>
            <div className="info_videos">
              {info?.item_youtube_embeddings?.videos?.map((e, i) => (
                <iframe
                  className="video_item"
                  width="1150"
                  height="681"
                  src={e.url}
                  title={e.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  frameBorder={0}
                ></iframe>
              ))}
            </div>
          </div>
        </div>
      ) : selectedItemTitle.toLowerCase() == "документы" ? (
        <div className="info_content">
          <div className="info_documents">
            {info?.item_documents?.documents?.map((e, i) => {
              return (
                <>
                  {e.url.includes(".rfa") ? (
                    <a className="document_item" href={e.url}>
                      <img
                        src="https://ajax-group.ru/wp-content/uploads/2019/02/rfa-266x300.png"
                        alt={e.title}
                        className="rsa_img"
                      />
                      <h6>{e.title}</h6>
                    </a>
                  ) : (
                    <a className="document_item" href={e.url}>
                      <img src={e.url} alt={e.title} />
                      <h6>{e.title}</h6>
                    </a>
                  )}
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="info_content">
          <div className="info_reviews_wrapper">
            {info?.item_reviews?.reviews?.length > 0 ? (
              <div>
                <div className="reviews_heading">
                  <h3>
                    {info?.item_reviews?.reviews?.length}{" "}
                    {parseInt(
                      `${info?.item_reviews?.reviews?.length}`[
                        info?.item_reviews?.reviews?.length - 1
                      ]
                    ) == 1
                      ? "ОТЗЫВ НА"
                      : parseInt(
                          `${info?.item_reviews?.reviews?.length}`[
                            info?.item_reviews?.reviews?.length - 1
                          ]
                        ) > 1 &&
                        parseInt(
                          `${info?.item_reviews?.reviews?.length}`[
                            info?.item_reviews?.reviews?.length - 1
                          ]
                        ) < 5
                      ? "ОТЗЫВА НА"
                      : "ОТЗЫВОВ НА"}
                  </h3>
                  <p>{info.item_title.toUpperCase()}</p>
                </div>
                <div className="info_reviews">
                  {info?.item_reviews?.reviews.map((e, i) => (
                    <div className="review_item">
                      {
                        <div>
                          <span>{`${e.person_name} – ${getDate(
                            e.timestamp
                          )}`}</span>
                          <p>{e.text}</p>
                        </div>
                      }
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <h3>ОТЗЫВЫ</h3>
                <p>Отзывов пока нет</p>
              </>
            )}
            <div className="reviews_underline"></div>
            <form
              className="review_form"
              onSubmit={(e) => postReview(e)}
              action=""
            >
              <h4>Добавить отзыв</h4>
              <p>
                Ваш адрес email не будет опубликован. Обязательные поля помечены
                *
              </p>
              <div className="form_field">
                <span>Имя</span>
                <input
                  type="text"
                  onChange={(e) => setUsernameValue(e.target.value)}
                />
              </div>
              <div className="form_field">
                <span>Email</span>
                <input
                  type="text"
                  onChange={(e) => setUserEmailValue(e.target.value)}
                />
              </div>
              <div className="star_rating_field">
                <span>ВАША ОЦЕНКА *</span>
                <div
                  className="star_rating"
                  onMouseLeave={() => setHoveredStarValue(-1)}
                >
                  {Array(5)
                    .fill(null)
                    .map((e, i) => (
                      <div key={i}>
                        {selectedStarValue ? (
                          <>
                            {selectedStarValue < i + 1 ? (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                fill="#000000"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 36.09 36.09"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M36.042,13.909c-0.123-0.377-0.456-0.646-0.85-0.688l-11.549-1.172L18.96,1.43c-0.16-0.36-0.519-0.596-0.915-0.596 s-0.755,0.234-0.915,0.598L12.446,12.05L0.899,13.221c-0.394,0.04-0.728,0.312-0.85,0.688c-0.123,0.377-0.011,0.791,0.285,1.055 l8.652,7.738L6.533,34.045c-0.083,0.387,0.069,0.787,0.39,1.02c0.175,0.127,0.381,0.191,0.588,0.191 c0.173,0,0.347-0.045,0.503-0.137l10.032-5.84l10.03,5.84c0.342,0.197,0.77,0.178,1.091-0.059c0.32-0.229,0.474-0.633,0.391-1.02 l-2.453-11.344l8.653-7.737C36.052,14.699,36.165,14.285,36.042,13.909z M25.336,21.598c-0.268,0.24-0.387,0.605-0.311,0.957 l2.097,9.695l-8.574-4.99c-0.311-0.182-0.695-0.182-1.006,0l-8.576,4.99l2.097-9.695c0.076-0.352-0.043-0.717-0.311-0.957 l-7.396-6.613l9.87-1.002c0.358-0.035,0.668-0.264,0.814-0.592l4.004-9.077l4.003,9.077c0.146,0.328,0.456,0.557,0.814,0.592 l9.87,1.002L25.336,21.598z"></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 47.94 47.94"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"></path>{" "}
                                </g>
                              </svg>
                            )}
                          </>
                        ) : (
                          <>
                            {hoveredStarValue < i ? (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                fill="#000000"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 36.09 36.09"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M36.042,13.909c-0.123-0.377-0.456-0.646-0.85-0.688l-11.549-1.172L18.96,1.43c-0.16-0.36-0.519-0.596-0.915-0.596 s-0.755,0.234-0.915,0.598L12.446,12.05L0.899,13.221c-0.394,0.04-0.728,0.312-0.85,0.688c-0.123,0.377-0.011,0.791,0.285,1.055 l8.652,7.738L6.533,34.045c-0.083,0.387,0.069,0.787,0.39,1.02c0.175,0.127,0.381,0.191,0.588,0.191 c0.173,0,0.347-0.045,0.503-0.137l10.032-5.84l10.03,5.84c0.342,0.197,0.77,0.178,1.091-0.059c0.32-0.229,0.474-0.633,0.391-1.02 l-2.453-11.344l8.653-7.737C36.052,14.699,36.165,14.285,36.042,13.909z M25.336,21.598c-0.268,0.24-0.387,0.605-0.311,0.957 l2.097,9.695l-8.574-4.99c-0.311-0.182-0.695-0.182-1.006,0l-8.576,4.99l2.097-9.695c0.076-0.352-0.043-0.717-0.311-0.957 l-7.396-6.613l9.87-1.002c0.358-0.035,0.668-0.264,0.814-0.592l4.004-9.077l4.003,9.077c0.146,0.328,0.456,0.557,0.814,0.592 l9.87,1.002L25.336,21.598z"></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 47.94 47.94"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"></path>{" "}
                                </g>
                              </svg>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <div className="form_field">
                <span>Ваш отзыв *</span>
                <textarea
                  ref={reviewFieldRef}
                  className="form_review_input"
                  spellCheck={false}
                  required
                />
              </div>
              <input
                type="submit"
                className="form_submit_button"
                value="ОТПРАВИТЬ"
              ></input>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
