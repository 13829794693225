import { TitleUnderline } from "../TitileUndeline";
import "./Advantages.scss";
import { AdvantagesCard } from "./AdvantagesCard";

export const Advantages = () => {
  return (
    <div className="advantages_wrapper">
      <h1>ПРЕИМУЩЕСТВА</h1>
      <TitleUnderline></TitleUnderline>
      <div className="advantages_container">
        <AdvantagesCard
          incrementableValueMax={10}
          description="ЛЕТ ОПЫТ РАБОТЫ"
        ></AdvantagesCard>
        <AdvantagesCard
          incrementableValueMax={50}
          description="ВЫСОКОКВАЛИФИЦИРОВАННЫХ СОТРУДНИКОВ"
        ></AdvantagesCard>
        <AdvantagesCard
          incrementableValueMax={300}
          description="БОЛЕЕ 300 ВЫПОЛНЕННЫХ ОБЪЕКТОВ"
        ></AdvantagesCard>
        <AdvantagesCard
          incrementableValueMax={365}
          description="РАБОЧИХ ДНЕЙ"
        ></AdvantagesCard>
      </div>
    </div>
  );
};
