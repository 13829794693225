import { FC } from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <div
      className={
        window.innerWidth > 1400 ? "logo_wrapper" : "logo_wrapper-clipped"
      }
    >
      <Link className="logo" to={"/"}>
        <img
          src="https://ajax-group.ru/wp-content/uploads/2022/07/logo-90-2.png"
          alt="logo"
        />
      </Link>
    </div>
  );
};
