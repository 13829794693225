import useFetch from "../../../../hooks/useFetch";
import { MerchandiseItemInfo } from "../../../../types";
import { serverUrl } from "../../../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useActions } from "../../../../hooks/useActions";
import { MerсhandiseCard } from "../../../Home/MerchandiseCards/MerchandiseCard";
import { CouplingMerchandiseCards } from "./CouplingMerchandiseCards";
import "./Coupling.scss";
import { TitleUnderline } from "../../../Home/TitileUndeline";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Coupling = () => {
  const { data, error, state } = useFetch<MerchandiseItemInfo[]>(
    serverUrl + "merchandise"
  );
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="coupling_page">
        <div className="coupling_description_wrapper">
          <h1 className="couping_page_heading">СТАЛЬНАЯ МУФТА ASTRA - ЭТО</h1>
          <div className="coupling_description_item">
            <h2>Качество</h2>
            <p>Стальные материалы и высокотехнологичное оборудование</p>
          </div>
          <div className="coupling_description_item">
            <h2>Герметичность</h2>
            <p>Соединение, которое исключает протечки системы</p>
          </div>
          <div className="coupling_description_item">
            <h2>Беспроблемность</h2>
            <p>Отсутствие проблем на этапе вварки</p>
          </div>
          <div className="coupling_description_item">
            <h2>Соответствие ГОСТу</h2>
            <p>Сертифицированный продукт, соответствует ГОСТу</p>
          </div>
        </div>
        <table className="comparison_wrapper">
          <thead></thead>
          <tbody>
            <tr>
              <td style={{ border: "none" }}></td>
              <td
                style={{
                  border: "none",
                  borderLeft: "1px solid #808080",
                  borderRight: "1px solid #808080",
                }}
              >
                <img src="/assets/couplingPage/m2-300x214.jpg" alt="" />
                <p>Муфта стальная</p>
              </td>
              <td style={{ border: "none", borderRight: "1px solid #fff" }}>
                <img src="/assets/couplingPage/m1-300x214.jpg" alt="" />
                <p>Муфта ASTRA</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Бесшовная труба марки Cт20</h2>
              </td>
              <td>
                <svg
                  fill="#0000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 490 490"
                  className="cross"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                  </g>
                </svg>
              </td>
              <td className="cell_highlited">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="check"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M4 12.6111L8.92308 17.5L20 6.5"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Идеальная резьба и наличие фаски</h2>
              </td>
              <td>
                <svg
                  fill="#0000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 490 490"
                  className="cross"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                  </g>
                </svg>
              </td>
              <td className="cell_highlited">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="check"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M4 12.6111L8.92308 17.5L20 6.5"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Подготовлена к покраске и сварке</h2>
              </td>
              <td>
                <svg
                  fill="#0000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 490 490"
                  className="cross"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                  </g>
                </svg>
              </td>
              <td className="cell_highlited">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="check"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M4 12.6111L8.92308 17.5L20 6.5"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Удобная упаковка и оперативность подсчета остатков</h2>
              </td>
              <td>
                <svg
                  fill="#0000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 490 490"
                  className="cross"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                  </g>
                </svg>
              </td>
              <td className="cell_highlited">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="check"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M4 12.6111L8.92308 17.5L20 6.5"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
        <CouplingMerchandiseCards />
      </div>
    </>
  );
};
