import { ArrowButton } from "./ArrowButton";
import { NavigationLink } from "./NavigationLink";

export const NavigationFull = () => {
  return (
    <>
      <ul className="navbar-full_navigation">
        <NavigationLink title="ГЛАВНАЯ" url="/" />
        <NavigationLink
          title="О КОМПАНИИ"
          url="about"
          additionalLinks={[]}
          additionalLinksTitles={[
            {
              title: "Сертификаты и лицензии",
              url: "certificates",
            },
            {
              title: "Выполненные объекты",
              url: "completed-contracts",
            },
            {
              title: "Наши партнеры",
              url: "partners",
            },
            {
              title: "Новости",
              url: "news",
            },
          ]}
        />
        <NavigationLink
          title="УСЛУГИ"
          url="services"
          additionalLinks={[]}
          additionalLinksTitles={[
            {
              title: "Проектирование",
              url: "services/design/",
              isNested: true,
              itemsNested: [
                {
                  title: "АУПТ",
                  url: "services/design/aupt",
                },
                {
                  title: "ВПВ",
                  url: "services/design/vpv",
                },
                {
                  title: "АПС",
                  url: "services/design/aps",
                },
                {
                  title: "СОУЭ",
                  url: "services/design/soue",
                },
                {
                  title: "АППЗ",
                  url: "services/design/appz",
                },
              ],
            },

            {
              title: "Монтажные работы АУПТ, ВПВ",
              url: "services/assembly-work/",
              isNested: false,
            },
            {
              title: "Электромонтажные работы ЭОМ, ЭС, СС",
              url: "services/electrical-work/",
            },
            {
              title: "Ливневая канализация",
              url: "services/drain",
            },
            {
              title: "Эксплуатация",
              url: "services/operation",
            },
          ]}
        />
        <NavigationLink
          title="ПРОДУКЦИЯ"
          url="merchandise"
          additionalLinks={[]}
          additionalLinksTitles={[
            {
              title: "Муфта спринклерная",
              url: "shop",
              isNested: false,
            },
            {
              title: "Защита оросителя",
              url: "merchandise/irrigation-protection",
              isNested: false,
            },
            {
              title: "Важное",
              url: "",
              isNested: true,
              isNestedAsList: true,
              itemsNested: [
                {
                  title: "Проектировщикам",
                  url: "/for-designers",
                },
                {
                  title: "Дилерам",
                  url: "/for-dealers",
                },
                {
                  title: "Доставка",
                  url: "/shipment",
                },
              ],
            },
          ]}
        />
        <NavigationLink title="КОНТАКТЫ" url="contacts" additionalLinks={[]} />
      </ul>
    </>
  );
};
