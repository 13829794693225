import { useRef, useState } from "react";
import { FormEvent } from "react";
import axios from "axios";
import { serverUrl } from "../../../../config";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import useFetch from "../../../../hooks/useFetch";
import { INewsItem } from "../../../../types";
import { NewsItem } from "../NewsItem";
import { EditMenu } from "../../ItemPage/Admin/EditMenu";
import { useActions } from "../../../../hooks/useActions";

export const NewsAdmin = () => {
  const { data, error } = useFetch<INewsItem[]>(serverUrl + "news");

  const isLoginSuccess = useSelector(
    (state: RootState) => state.loginReducer.isLoginSuccessful
  );

  const { setEditMenuState } = useActions();

  const [showEditMenu, setShowEditMenu] = useState(false);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const loginAsAdmin = async (e: FormEvent) => {
    e.preventDefault();
    console.log(usernameInputRef.current?.value);
    console.log(passwordInputRef.current?.value);
    const response = await axios.post(serverUrl + "admin-login", {
      login: usernameInputRef.current?.value,
      password: passwordInputRef.current?.value,
    });
    console.log(response);
    localStorage.setItem("token", JSON.stringify(response.data.token));
    window.location.reload();
  };

  return (
    <>
      {isLoginSuccess ? (
        <>
          {showEditMenu ? (
            <EditMenu setDisplayEditMenu={setShowEditMenu} />
          ) : (
            <></>
          )}
          <div className="news_wrapper">
            <h1>новости</h1>
            <div className="news_content">
              {data ? (
                <>
                  {" "}
                  {data.map((e, i) => (
                    <NewsItem newsItem={e} key={i} />
                  ))}
                  <div
                    className="news_item_add"
                    onClick={() => {
                      const now = new Date();
                      setEditMenuState({
                        url: `${serverUrl}post-news`,
                        propertiesArray: [
                          {
                            title: "Заголовок",
                            initialValue: "",
                            propertyName: "title",
                            type: "text",
                            dataType: "string",
                          },
                          {
                            title: "Текст новости",
                            initialValue: "",
                            propertyName: "content",
                            type: "text",
                            dataType: "string",
                          },
                          {
                            title:
                              "Название файла с картинкой в папке news(с расширением)",
                            initialValue: "",
                            propertyName: "content",
                            type: "text",
                            dataType: "string",
                          },
                          {
                            title:
                              "Название файла с картинкой в папке news(с расширением)",
                            initialValue: now.toISOString(),
                            propertyName: "content",
                            type: "text",
                            dataType: "string",
                            notEditable: true,
                          },
                        ],
                      });
                      setShowEditMenu(true);
                    }}
                  >
                    {" "}
                    <svg
                      fill="#000000"
                      height="200px"
                      width="200px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 455 455"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5 455,242.5 "></polygon>{" "}
                      </g>
                    </svg>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <form className="auth_form" onSubmit={(e) => loginAsAdmin(e)}>
          <img
            src="https://ajax-group.ru/wp-content/uploads/2022/07/logo-90-2.png"
            alt=""
            className="auth_logo"
          />
          <input
            ref={usernameInputRef}
            type="text"
            name="Логин"
            id=""
            placeholder="Логин"
            className="auth_form_field"
          />
          <input
            ref={passwordInputRef}
            type="text"
            name="Пароль"
            id=""
            placeholder="Пароль"
            className="auth_form_field"
          />
          <button className="submit_button">Войти</button>
        </form>
      )}
    </>
  );
};
