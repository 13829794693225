import React, { useEffect, useRef, useState } from "react";
import Home from "./components/Home/Home";
import "./App.css";
import Header from "./components/Header/Header";
import Burger from "./components/BurgerMenu/Burger";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { RootState } from "./store/store";
import { Footer } from "./components/Footer/Footer";
import { MerchandiseItemPage } from "./components/Pages/ItemPage/MerchandiseItemPage";
import { MerchandiseItemPageAdmin } from "./components/Pages/ItemPage/Admin/MerchandiseitemPageAdmin";
import { ShopPage } from "./components/Pages/ShopPage/ShopPage";
import useFetch from "./hooks/useFetch";
import { serverUrl } from "./config";
import { useActions } from "./hooks/useActions";
import { OrderPage } from "./components/Pages/OrderPage/OrderPage";
import { CertificatesPage } from "./components/Pages/Certificates/CertificatesPage";
import { CompletedContractsPage } from "./components/Pages/CompletedContracts/CompletedContractsPage";
import { CompletedContractPage } from "./components/Pages/CompletedContracts/CompletetedContractPage/CompletedContractPage";
import { NotFound } from "./components/Pages/404/404";
import { PartnersPage } from "./components/Pages/PartnersPage/PartnersPage";
import { News } from "./components/Pages/News/News";
import { About } from "./components/Pages/About/About";
import { ServicesPage } from "./components/Pages/Services/ServicesPage";
import { DesignPage } from "./components/Pages/Services/DesignPage/DesignPage";
import { Aupt } from "./components/Pages/Services/DesignPage/Appz/Aupt";
import { ServiceFormPage } from "./components/Pages/Services/ServiceFormPage";
import { AssemblyWorks } from "./components/Pages/Services/ServiceDescriptions/AssemblyWorks";
import { ElectricalWorks } from "./components/Pages/Services/ServiceDescriptions/ElectricalWorks";
import { DrainWorks } from "./components/Pages/Services/ServiceDescriptions/DrainWorks";
import { Operations } from "./components/Pages/Services/ServiceDescriptions/Operations";
import { MerchandisePage } from "./components/Pages/Merchandise/MerchandisePage";
import { Coupling } from "./components/Pages/Merchandise/Coupling/Coupling";
import { IrrigationProtection } from "./components/Pages/Merchandise/IrrigationProtection/IrrigationProtection";
import { ForDesigners } from "./components/Pages/ForDesigners";
import { ForDealers } from "./components/Pages/ForDealers/ForDealers";
import { Shipment } from "./components/Pages/Shipment";
import { Contacts } from "./components/Pages/Contacts/Contacts";
import { Cart } from "./components/Pages/Cart/CartPage";
import { PrivacyPolicy } from "./components/Pages/PrivacyPolicy";
import { NewsAdmin } from "./components/Pages/News/Admin/NewsAdmin";

function App() {
  const token = JSON.parse(localStorage.getItem("token") || "null");

  const { setLoginState } = useActions();

  const { data, error } = useFetch<{ error?: string; success?: string }>(
    serverUrl + "admin-login/" + token
  );

  useEffect(() => {
    if (data && data.error) {
      setLoginState(false);
    }
    if (data && data.success) {
      setLoginState(true);
    }
    if (error) {
      setLoginState(false);
    }
  }, [data]);

  const [isBurgerMenuActive, setIsBurgerMenuActive] = useState<
    "active" | "idle" | "unactive"
  >("idle");
  return (
    <Router>
      <div className="App">
        <Header setIsBurgerMenuActive={setIsBurgerMenuActive} />
        <Burger
          setIsBurgerMenuActive={setIsBurgerMenuActive}
          burgerMenuState={isBurgerMenuActive}
        />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="shop/:id/admin"
            element={<MerchandiseItemPageAdmin />}
          ></Route>
          <Route path="shop/:id" element={<MerchandiseItemPage />}></Route>
          <Route path="shop" element={<ShopPage />}></Route>
          <Route
            path="completed-contracts"
            element={<CompletedContractsPage />}
          ></Route>
          <Route
            path="completed-contracts/:id"
            element={<CompletedContractPage />}
          ></Route>
          <Route path="partners" element={<PartnersPage />}></Route>
          <Route path="cart" element={<Cart></Cart>}></Route>
          <Route path="checkout" element={<OrderPage />}></Route>
          <Route path="certificates" element={<CertificatesPage />}></Route>
          <Route path="news" element={<News></News>}></Route>
          <Route path="news/admin" element={<NewsAdmin />}></Route>
          <Route path="about" element={<About />}></Route>
          <Route
            path="mufta-sprinclernaia-privarnaia"
            element={<Navigate to="/" replace={true} />}
          ></Route>
          <Route path="services" element={<ServicesPage />}></Route>
          <Route
            path="services/design"
            element={<DesignPage></DesignPage>}
          ></Route>
          <Route path="services/design/aupt" element={<Aupt />}></Route>
          <Route
            path="services/design/:id"
            element={<ServiceFormPage />}
          ></Route>
          <Route
            path="services/design/aupt/:id"
            element={<ServiceFormPage />}
          ></Route>
          <Route
            path="services/assembly-work"
            element={<AssemblyWorks />}
          ></Route>
          <Route
            path="services/electrical-work"
            element={<ElectricalWorks />}
          ></Route>
          <Route path="services/drain" element={<DrainWorks />}></Route>
          <Route path="services/operation" element={<Operations />}></Route>
          <Route path="merchandise" element={<MerchandisePage />}></Route>
          <Route path="merchandise/coupling" element={<Coupling />}></Route>
          <Route
            path="merchandise/irrigation-protection"
            element={<IrrigationProtection />}
          ></Route>
          <Route path="for-designers" element={<ForDesigners />}></Route>
          <Route path="for-dealers" element={<ForDealers />}></Route>
          <Route path="shipment" element={<Shipment />}></Route>
          <Route path="contacts" element={<Contacts />}></Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="404" element={<NotFound />}></Route>
          <Route
            path="*"
            element={<Navigate to="404" replace={true} />}
          ></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
