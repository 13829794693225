import { Link } from "react-router-dom";
import "./IrrigationProtection.scss";

export const IrrigationProtection = () => {
  return (
    <div className="irrigation_protection_page">
      <h1>Защита оросителя</h1>
      <>
        {/* Hello world */}
        <article className="irrigation_protection_description_wrapper">
          <div className="irrigation_protection_description">
            <p>
              Стеллажные спринклерные ограждения DAK K3 WERKS были разработаны с
              учетом различных складских систем. Мы создали несколько размеров и
              регулируемую защиту спринклера, чтобы учесть различные
              пространства дымохода, глубину залива и расположение спринклерной
              головки. Самый большой вопрос, который мы получили до сих пор: как
              мне определить, какой спринклер подходит для моей системы? Мы
              здесь, чтобы ответить на этот вопрос!
            </p>
            <p>1. Найдите спринклерную головку.</p>
            <p>
              2. Если он находится в пределах 6,5 дюймов от балки, вы можете
              использовать спринклерную защиту, установленную на одной балке.
            </p>
            <h4>&nbsp;</h4>
            <img
              decoding="async"
              className="size-medium wp-image-12444 aligncenter"
              src="/assets/irrigation-protection/zashchita-orositelia-01.jpeg"
              alt=""
              width={300}
              height={222}
              //srcSet="https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-01-300x222.jpeg 300w, https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-01-600x444.jpeg 600w, https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-01.jpeg 750w"
              sizes="(max-width: 300px) 100vw, 300px"
            />
            <p>
              3. Если головка спринклера находится в пространстве дымохода или в
              эркерной секции, следует использовать спринклерную защиту,
              устанавливаемую на двойную балку. Эти ограждения были созданы с
              учетом общей глубины пространства дымохода и вертикальной глубины.
              У нас есть ограждение для дымоходов 10-12 дюймов, дымоходов 16-18
              дюймов, вертикальной глубины 42-44 дюйма и ограждение, которое
              регулируется от 36 до 54 дюймов, если статическое ограждение не
              соответствует вашему пространству должным образом.
            </p>
            <h4>&nbsp;</h4>
            <img
              decoding="async"
              className="size-medium wp-image-12445 aligncenter"
              src="/assets/irrigation-protection/zashchita-orositelia-02.jpeg"
              alt=""
              width={300}
              height={255}
            />
            <img
              decoding="async"
              className="size-medium wp-image-12446 aligncenter"
              src="https://ajax-group.ru/wp-content/uploads/2023/02/zashchita-orositelia-03.jpeg"
              alt=""
              width={292}
              height={300}
              srcSet="https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-03.jpeg 292w, https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-03-50x50.jpeg 50w"
              sizes="(max-width: 292px) 100vw, 292px"
            />
            <p>
              4. Чтобы определить глубину пространства дымохода, измерьте
              расстояние от лицевой стороны одной балки до лицевой стороны
              соседней балки.
            </p>
            <p>5. Чтобы определить глубину ниши, измерьте глубину стойки.</p>
            <p>
              6. Выберите размер защиты разбрызгивателя на основе ваших
              измерений.
            </p>
            <p>
              7. Примечание: если расстояние от нижней части балки до нижней
              части спринклерной головки больше 3,5″, вам понадобится смещенный
              кронштейн.
            </p>
            <img
              loading="lazy"
              decoding="async"
              className="size-medium wp-image-12447 aligncenter"
              src="https://ajax-group.ru/wp-content/uploads/2023/02/zashchita-orositelia-04-300x216.png"
              alt=""
              width={300}
              height={216}
              srcSet="https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-04-300x216.png 300w, https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-04.png 352w"
              sizes="(max-width: 300px) 100vw, 300px"
            />
            <p className="link_wrapper">
              <Link
                to="https://www.youtube.com/watch?v=m7tolEPcfjc"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                <div>
                  <strong>
                    НОВОЕ ВИДЕО! Испытание на удар спринклерной защиты
                  </strong>
                </div>
              </Link>
            </p>
            <div className="wp-block-image">
              <figure className="aligncenter size-full">
                <img
                  loading="lazy"
                  decoding="async"
                  width={600}
                  height={733}
                  src="https://ajax-group.ru/wp-content/uploads/2023/02/zashchita-orositelia-05.png"
                  alt=""
                  className="wp-image-12448"
                  srcSet="https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-05.png 600w, https://cr76168.tmweb.ru/wp-content/uploads/2023/02/zashchita-orositelia-05-246x300.png 246w"
                  sizes="(max-width: 600px) 100vw, 600px"
                />
              </figure>
            </div>{" "}
            <div className="inner-container"></div>
            {/* .inner-container */}
          </div>
          {/* .entry-content */}
        </article>
      </>
    </div>
  );
};
