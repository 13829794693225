import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchandiseItemInfo } from "../../types";

export interface CartItemInfo extends MerchandiseItemInfo {
  cartItemsCount: number;
}

interface CartState {
  itemsArray: CartItemInfo[];
  itemsTotalCost: number;
}

const localCart = JSON.parse(localStorage.getItem("cart") || "[]");

const initialState: CartState = {
  itemsArray: localCart,
  itemsTotalCost: localCart.reduce((prev: number, curr: CartItemInfo) => {
    return prev + curr.cartItemsCount;
  }, 0),
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    setCartState(state, action: PayloadAction<CartItemInfo[]>) {
      state.itemsArray = action.payload;
      localStorage.setItem("cart", JSON.stringify(action.payload));
    },
  },
});

export const cartSliceActions = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
