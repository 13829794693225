import BurgerButton from "./BurgerButton";
import "./Header.scss";
import { useEffect, useState, useRef } from "react";
import { Logo } from "./Logo";
import { Cart } from "./Cart";
import { NavigationFull } from "./NavigationFull/NavigationFull";
import { FC } from "react";

interface Props {
  setIsBurgerMenuActive: React.Dispatch<
    React.SetStateAction<"idle" | "active" | "unactive">
  >;
}

const Header: FC<Props> = ({ setIsBurgerMenuActive }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowScroll, setWindowScroll] = useState<number>(window.scrollY);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };
  const setWindowPosition = () => {
    setWindowScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    window.addEventListener("scroll", setWindowPosition);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
      window.removeEventListener("scroll", setWindowPosition);
    };
  }, []);

  return (
    <>
      <header
        className={
          windowWidth > 1200 ? "header_wrapper-full" : "header_wrapper-clipped"
        }
        /* style={
          window.scrollY > 0 ? { position: "fixed" } : { position: "sticky" }
        } */
      >
        {windowWidth > 1200 ? (
          <nav className="header_navbar-full">
            <Logo />
            <NavigationFull></NavigationFull>
            <Cart></Cart>
          </nav>
        ) : (
          <nav className="header_navbar-clipped">
            <div className="navbar-clipped_navigation">
              <BurgerButton
                setIsBurgerMenuActive={setIsBurgerMenuActive}
              ></BurgerButton>
              <Logo />
            </div>
            <Cart></Cart>
          </nav>
        )}
      </header>
    </>
  );
};
export default Header;
