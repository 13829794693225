import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { AppDispatch } from "../store/store";
import { cartSliceActions } from "../store/reducers/cartSlice";
import { editMenuActions } from "../store/reducers/editMenuSlice";
import { loginSliceActions } from "../store/reducers/loginSlice";

const allActions = {
  ...cartSliceActions,
  ...editMenuActions,
  ...loginSliceActions,
};

export const useActions = () => {
  const dispatch = useDispatch<AppDispatch>();

  return bindActionCreators(allActions, dispatch);
};
