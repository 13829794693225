import "../DesignPage.scss";
import "../../../Page.scss";
import "./Aupt.scss";
import { Link } from "react-router-dom";
export const Aupt = () => {
  return (
    <div className="page_wrapper">
      <h1 className="page_heading">АУПТ</h1>
      <div className="aupt_services_grid">
        <div className="grid_item">
          <Link to="aerosol-extinguish">
            Система аэрозольного пожаротушения
          </Link>
        </div>
        <div className="grid_item">
          <Link to="gas-extinguish">Система газового пожаротушения</Link>
        </div>
        <div className="grid_item">
          <Link to="powder-extinguish">Система порошкового пожаротушения</Link>
        </div>
        <div className="grid_item">
          <Link to="water-spray-extinguish">
            Система водяного (спринклерного) пожаротушения, дренчерные завесы,
            лафетные стволы
          </Link>
        </div>
        <div className="grid_item">
          <Link to="foam-extinguish">Система пенного пожаротушения</Link>
        </div>
        <div className="grid_item">
          <Link to="thin-spray-extinguish">
            Установки тушения тонкораспыленной водой модульного и агрегатного
            исполнения
          </Link>
        </div>
      </div>
    </div>
  );
};
