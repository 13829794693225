import useFetch from "../../../hooks/useFetch";
import { MerchandiseItemInfo } from "../../../types";
import { Link } from "react-router-dom";
import "./ShopPage.scss";
import { useEffect, useState } from "react";
import { MerсhandiseCard } from "../../Home/MerchandiseCards/MerchandiseCard";
import { serverUrl } from "../../../config";
import { Helmet } from "react-helmet";
import { MerсhandiseCardList } from "./MerchandiseCardList";

interface Filters {
  item_diameter?: string[];
  item_thread?: string[];
  item_groove?: boolean[];
  item_length?: string[];
}

export const ShopPage = () => {
  const { data, error, state } = useFetch<MerchandiseItemInfo[]>(
    serverUrl + "shop"
  );

  const [filters, setFilters] = useState<Filters>({});

  const [displayMode, setDisplayMode] = useState<"grid" | "list">("grid");

  const [filteredData, setFilteredData] = useState<
    MerchandiseItemInfo[] | null
  >(null);

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const handleFilterAdd = (propertyName: string, value: any) => {
    if (typeof value == "number") {
      value = !!value;
    }
    if (filters) {
      setFilters({
        ...filters,
        [propertyName]: filters[propertyName as keyof Filters]
          ? [...(filters[propertyName as keyof Filters] as string[]), value]
          : [value],
      });
    } else {
      setFilters({ [propertyName]: [value] });
    }
  };

  const handleFilterRemove = (
    propertyName: string,
    value: string | number | boolean
  ) => {
    if (typeof value == "number") {
      value = !!value;
    }

    if (filters) {
      console.log(Object.keys(filters).length);

      setFilters({
        ...filters,
        [propertyName]: [
          ...(filters[propertyName as keyof Filters] as string[]),
        ].filter((e: any) => e != value),
      });
    }
  };

  useEffect(() => {
    if (data) {
      console.log(filteredData);
      console.log(filters);
      console.log(Object.keys(filters));

      const commonLength = Object.values(filters).reduce((prev, curr) => {
        return prev + curr;
      }, 0);

      var newFilteredValues: any[] = [];

      setFilteredData(
        data.filter((e, index) => {
          if (
            Object.keys(filters).every((filterProperty, i) => {
              if (filters[filterProperty as keyof Filters]?.length == 0) {
                return true;
              }
              if (
                [
                  ...(filters[filterProperty as keyof Filters] as string[]),
                ]?.filter((value, i) => {
                  {
                    return (
                      value == e[filterProperty as keyof MerchandiseItemInfo]
                    );
                  }
                }).length > 0
              ) {
                return true;
              }
            })
          ) {
            return e;
          }
        })
      );

      //SEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEXSEX
      console.log(newFilteredValues);

      if (commonLength == 0 && data) {
        setFilteredData(data);
      }
    }
  }, [filters]);

  useEffect(() => {
    console.log(filteredData);
  }, [filteredData]);

  const getFilteredOptions = (
    property: string,
    postfix: string,
    prefix: string,
    isBoolean?: boolean,
    booleanReplaceValueTrue?: any,
    booleanReplaceValueFalse?: any
  ) => {
    if (data) {
      /*  ? filteredData.map((e, i) => {
            return e[property as keyof MerchandiseItemInfo];
          })
        :  */ const filterOptions = data.map((e, i) => {
        return e[property as keyof MerchandiseItemInfo];
      });
      return (
        <>
          <Helmet>
            <title>Продукция - ООО «АЯКС»</title>
            <meta name="description" content="Интернет-магазин ajax-group.ru" />
          </Helmet>
          {Array.from(new Set([...filterOptions])).map((item, i) => (
            <label
              htmlFor={"item" + i + property}
              className="item_label"
              key={i}
            >
              <input
                type="checkbox"
                key={i}
                name={property}
                id={"item" + i + property}
                className="label_radio"
                value={item}
                onChange={(e) => {
                  e.target.checked
                    ? handleFilterAdd(property, item)
                    : handleFilterRemove(property, item);
                }}
              />
              <span className="custom_radio"></span>
              <span className="label_title">
                {isBoolean
                  ? item
                    ? booleanReplaceValueTrue
                    : booleanReplaceValueFalse
                  : `${prefix}${item}${postfix}`}
              </span>
              <span className="label_count">
                (
                {
                  filterOptions.filter((e) => {
                    return e == item;
                  }).length
                }
                )
              </span>
            </label>
          ))}
        </>
      );
    }
  };

  return (
    <div className="shop_page">
      {filteredData ? (
        <div className="shop_content_wrapper">
          <div className="shop_heading">
            <h1>ПРОДУКЦИЯ</h1>
            <div className="shop_navigation">
              <Link to="/">Главная</Link> / <span>Продукция</span>
            </div>
          </div>
          <div className="shop_content">
            <div className="content_filters">
              <span className="filters_display">
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  onClick={() => setDisplayMode("grid")}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M9.5 2h-6A1.502 1.502 0 0 0 2 3.5v6A1.502 1.502 0 0 0 3.5 11h6A1.502 1.502 0 0 0 11 9.5v-6A1.502 1.502 0 0 0 9.5 2zm.5 7.5a.501.501 0 0 1-.5.5h-6a.501.501 0 0 1-.5-.5v-6a.501.501 0 0 1 .5-.5h6a.501.501 0 0 1 .5.5zM20.5 2h-6A1.502 1.502 0 0 0 13 3.5v6a1.502 1.502 0 0 0 1.5 1.5h6A1.502 1.502 0 0 0 22 9.5v-6A1.502 1.502 0 0 0 20.5 2zm.5 7.5a.501.501 0 0 1-.5.5h-6a.501.501 0 0 1-.5-.5v-6a.501.501 0 0 1 .5-.5h6a.501.501 0 0 1 .5.5zM9.5 13h-6A1.502 1.502 0 0 0 2 14.5v6A1.502 1.502 0 0 0 3.5 22h6a1.502 1.502 0 0 0 1.5-1.5v-6A1.502 1.502 0 0 0 9.5 13zm.5 7.5a.501.501 0 0 1-.5.5h-6a.501.501 0 0 1-.5-.5v-6a.501.501 0 0 1 .5-.5h6a.501.501 0 0 1 .5.5zM20.5 13h-6a1.502 1.502 0 0 0-1.5 1.5v6a1.502 1.502 0 0 0 1.5 1.5h6a1.502 1.502 0 0 0 1.5-1.5v-6a1.502 1.502 0 0 0-1.5-1.5zm.5 7.5a.501.501 0 0 1-.5.5h-6a.501.501 0 0 1-.5-.5v-6a.501.501 0 0 1 .5-.5h6a.501.501 0 0 1 .5.5z"></path>
                  </g>
                </svg>
                <svg
                  fill="#000000"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="list_order_display"
                  onClick={() => setDisplayMode("list")}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path d="M277.328,0v128H448V0H277.328z M432,112H293.328V16H432V112z"></path>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path d="M277.328,192v128H448V192H277.328z M432,304H293.328v-96H432V304z"></path>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path d="M277.328,384v128H448V384H277.328z M432,496H293.328v-96H432V496z"></path>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          x="64"
                          y="23.664"
                          width="170.656"
                          height="16"
                        ></rect>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          x="64"
                          y="87.664"
                          width="170.656"
                          height="16"
                        ></rect>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          x="64"
                          y="215.664"
                          width="170.656"
                          height="16"
                        ></rect>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          x="64"
                          y="279.664"
                          width="170.656"
                          height="16"
                        ></rect>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          x="64"
                          y="407.664"
                          width="170.656"
                          height="16"
                        ></rect>{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          x="64"
                          y="471.664"
                          width="170.656"
                          height="16"
                        ></rect>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
                <span className="items_count">
                  Представлено {filteredData?.length || 0}{" "}
                  {filteredData?.length.toString()[filteredData.length] == "1"
                    ? "товар"
                    : filteredData?.length.toString()[filteredData.length] ==
                        "2" ||
                      filteredData?.length.toString()[filteredData.length] ==
                        "4" ||
                      filteredData?.length.toString()[filteredData.length] ==
                        "3"
                    ? "товара"
                    : "товаров"}
                </span>
              </span>
              <div className="filters_properties">
                <div className="properties_item">
                  <h3>ДИАМЕТР</h3>
                  {getFilteredOptions("item_diameter", "", "Ду")}
                </div>
                <div className="properties_item">
                  <h3>РЕЗЬБА</h3>
                  {getFilteredOptions("item_thread", '"', "")}
                </div>
                <div className="properties_item">
                  <h3>ПРОТОЧКА</h3>
                  {getFilteredOptions("item_groove", "", "", true, "да", "нет")}
                </div>
                <div className="properties_item">
                  <h3>ДЛИНА ИЗДЕЛИЯ</h3>
                  {getFilteredOptions("item_length", "мм", "")}
                </div>
              </div>
            </div>
            <div className={`content_shop_${displayMode}`}>
              {displayMode == "list"
                ? filteredData.map((e, i) => (
                    <MerсhandiseCardList info={{ ...e, cartItemsCount: 0 }} />
                  ))
                : filteredData.map((e, i) => (
                    <MerсhandiseCard info={{ ...e, cartItemsCount: 0 }} />
                  ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="shop_content_wrapper">
          <div className="loading_background">
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
