import { CartItemInfo } from "../../../store/reducers/cartSlice";
import { FC, useEffect, useRef, useState } from "react";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
interface Props {
  setSelectedItems: React.Dispatch<React.SetStateAction<CartItemInfo[]>>;
  selectedItems: CartItemInfo[];
  globalSelected: boolean;
  item: CartItemInfo;
  index: number;
  setShowConfirmationButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingItem: React.Dispatch<React.SetStateAction<CartItemInfo | null>>;
}
export const CartItem: FC<Props> = ({
  selectedItems,
  setSelectedItems,
  globalSelected,
  item,
  index,
  setPendingItem,
  setShowConfirmationButton,
}) => {
  const { setCartState } = useActions();

  const cartState = useSelector(
    (state: RootState) => state.cartReducer.itemsArray
  );
  const [isLocallySelected, setIsLocallySelected] = useState(false);

  const [showSuggestedOptions, setShowSuggestedOptions] = useState(false);

  const handleSelectItem = (item: CartItemInfo) => {
    setSelectedItems([...selectedItems, item]);
  };

  const suggestedOptionsRef = useRef<HTMLDivElement>(null);

  const handleUnselectItem = (item: CartItemInfo) => {
    setSelectedItems(
      selectedItems.filter((e, i) => {
        return item.item_key != e.item_key;
      })
    );
  };

  const handleItemsCountChange = (item: CartItemInfo, value: number) => {
    const foundItemIndex = cartState.findIndex(
      (e) => e.item_key === item?.item_key
    );
    setCartState(
      cartState.map((e, i) => {
        if (i == foundItemIndex) {
          return { ...e, cartItemsCount: value };
        }
        return e;
      })
    );
  };

  useEffect(() => {
    if (suggestedOptionsRef.current && showSuggestedOptions) {
      suggestedOptionsRef.current.scrollTo({ top: 4000 });
    }
  }, [showSuggestedOptions]);

  return (
    <div className="cart_item" key={index}>
      <span className="index">{index + 1}</span>
      <input
        type="checkbox"
        className="checkbox"
        checked={selectedItems.includes(item)}
        onChange={(event) => {
          if (event.currentTarget.checked) {
            handleSelectItem(item);
            setIsLocallySelected(true);
          } else {
            handleUnselectItem(item);
            setIsLocallySelected(false);
          }
        }}
      />
      <img src={`assets/merchandise/${item.item_img}`} alt={item.item_title} />
      <div className="info">
        <div className="vendor_code">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M9 15H5C3.89543 15 3 14.1046 3 13V5C3 3.89543 3.89543 3 5 3H13C14.1046 3 15 3.89543 15 5V9M11 21H19C20.1046 21 21 20.1046 21 19V11C21 9.89543 20.1046 9 19 9H11C9.89543 9 9 9.89543 9 11V19C9 20.1046 9.89543 21 11 21Z"
                stroke="#808080"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
          <span>{item.item_vendor_code}</span>
        </div>
        <h3>{item.item_title}</h3>
        <p>В наличии</p>
        <p>Доступен самовывоз</p>
      </div>
      <div className="item_count_wrapper">
        <input
          className={
            showSuggestedOptions ? "item_count-unactive" : "item_count"
          }
          defaultValue={item.cartItemsCount}
          value={item.cartItemsCount}
          onChange={(e) => {
            handleItemsCountChange(item, parseInt(e.target.value) || 0);
          }}
        />
        {showSuggestedOptions ? (
          <div className="suggested_options" ref={suggestedOptionsRef}>
            {Array(100)
              .fill(null)
              .map((e, i) => (
                <div key={i}>
                  <span
                    onClick={() => {
                      handleItemsCountChange(item, i + 1);
                      setShowSuggestedOptions(false);
                    }}
                  >
                    {i + 1}
                  </span>
                </div>
              ))
              .reverse()}
          </div>
        ) : (
          <></>
        )}
        <svg
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
          onClick={() => {
            setShowSuggestedOptions((e) => !e);
          }}
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
              fill="#000000"
            ></path>
          </g>
        </svg>
      </div>
      <div className="item_cost_wrapper">
        <div>{item.item_cost * item.cartItemsCount}₽</div>
        <div className="item_price">
          {item.item_cost}₽ × {item.cartItemsCount}
        </div>
      </div>
      <div className="remove_item">
        {" "}
        <svg
          onClick={() => {
            setPendingItem(item);
            setShowConfirmationButton(true);
          }}
          viewBox="0 -0.5 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
              fill="#000000"
            ></path>{" "}
          </g>
        </svg>
      </div>
    </div>
  );
};
