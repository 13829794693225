import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  assets: { img: string; file: string }[];
  title: string;
}

export const Certificateitem: FC<Props> = ({ assets, title }) => {
  return (
    <div className="certificate_wrapper">
      <h2>{title}</h2>
      <div className="certificate_item">
        {assets.map((e, i) => (
          <a href={e.file}>
            <img style={{ maxWidth: "300px" }} src={e.img} alt={title} />
          </a>
        ))}
      </div>
    </div>
  );
};
