export const Shipment = () => {
  return (
    <div className="page_wrapper">
      <h1>Доставка</h1>
      <div style={{ margin: "0 auto", width: "100%", height: "400px" }}>
        <p className="text_wrapper">
          Доставка осуществляется во все города РФ (до терминала ПЭК) бесплатно
          при заказе от 2 000 штук.
        </p>
      </div>
    </div>
  );
};
