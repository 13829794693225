import "./Contacts.scss";

export const Contacts = () => {
  return (
    <div className="page_wrapper">
      <h1>контакты</h1>
      <div className="contacts_wrapper">
        <div className="contacts">
          <h2>ООО "АЯКС"</h2>
          <div className="contact_description">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.9951 9.90096C15.2798 9.43957 17 7.42067 17 5C17 2.23858 14.7614 0 12 0C9.23857 0 6.99999 2.23858 6.99999 5C6.99999 7.42067 8.72019 9.43957 11.0048 9.90096C11.0016 9.93354 11 9.96658 11 10V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V10C13 9.96658 12.9984 9.93354 12.9951 9.90096ZM12 8.0065C10.3396 8.0065 8.9935 6.66044 8.9935 5C8.9935 3.33956 10.3396 1.9935 12 1.9935C13.6604 1.9935 15.0065 3.33956 15.0065 5C15.0065 6.66044 13.6604 8.0065 12 8.0065Z"
                  fill="#fd3c23"
                ></path>{" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.78369 16.299C5.62603 15.6557 6.73206 15.2123 8.00216 15.0591C8.55047 14.993 8.99998 15.4477 8.99998 16C8.99998 16.5523 8.54907 16.9896 8.00393 17.0782C7.16144 17.215 6.4816 17.5189 5.99751 17.8885C5.32619 18.4012 5.09028 18.9788 5.12241 19.4288C5.15277 19.8538 5.44623 20.4684 6.50713 21.0185C7.57831 21.5739 9.33864 22 11.9999 22C14.6613 22 16.4216 21.5739 17.4928 21.0185C18.5537 20.4684 18.8472 19.8538 18.8775 19.4288C18.9097 18.9788 18.6738 18.4012 18.0024 17.8885C17.5184 17.5189 16.8385 17.215 15.996 17.0782C15.4509 16.9896 15 16.5523 15 16C15 15.4477 15.4495 14.993 15.9978 15.0591C17.2679 15.2123 18.3739 15.6557 19.2163 16.299C20.2637 17.0988 20.9653 18.2712 20.8724 19.5712C20.7778 20.8962 19.8838 22.0316 18.4134 22.794C16.9533 23.5511 14.8387 24 11.9999 24C9.16123 24 7.04656 23.5511 5.58649 22.794C4.11615 22.0316 3.22212 20.8962 3.12749 19.5712C3.03464 18.2712 3.73625 17.0988 4.78369 16.299Z"
                  fill="#fd3c23"
                ></path>{" "}
              </g>
            </svg>
            <span>
              <b>Адрес:</b> Московская область, г. Мытищи, ул. Силикатная 39Ж
            </span>
          </div>
          <div className="contact_description">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 405.333 405.333"
              width={24}
            >
              <g>
                <g>
                  <path
                    d="M373.333,266.88c-25.003,0-49.493-3.904-72.704-11.563c-11.328-3.904-24.192-0.896-31.637,6.699l-46.016,34.752    c-52.8-28.181-86.592-61.952-114.389-114.368l33.813-44.928c8.512-8.512,11.563-20.971,7.915-32.64    C142.592,81.472,138.667,56.96,138.667,32c0-17.643-14.357-32-32-32H32C14.357,0,0,14.357,0,32    c0,205.845,167.488,373.333,373.333,373.333c17.643,0,32-14.357,32-32V298.88C405.333,281.237,390.976,266.88,373.333,266.88z"
                    fill="#fa6755"
                  ></path>
                </g>
              </g>
            </svg>
            <span>
              <b>Телефон:</b> <a href="tel:+79251887777">+7 (925) 188-7777</a>
            </span>
          </div>
          <div className="contact_description">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M10.688,95.156C80.958,154.667,204.26,259.365,240.5,292.01c4.865,4.406,10.083,6.646,15.5,6.646     c5.406,0,10.615-2.219,15.469-6.604c36.271-32.677,159.573-137.385,229.844-196.896c4.375-3.698,5.042-10.198,1.5-14.719     C494.625,69.99,482.417,64,469.333,64H42.667c-13.083,0-25.292,5.99-33.479,16.438C5.646,84.958,6.313,91.458,10.688,95.156z"
                      fill="#fa6755"
                    ></path>
                    <path
                      d="M505.813,127.406c-3.781-1.76-8.229-1.146-11.375,1.542C416.51,195.01,317.052,279.688,285.76,307.885     c-17.563,15.854-41.938,15.854-59.542-0.021c-33.354-30.052-145.042-125-208.656-178.917c-3.167-2.688-7.625-3.281-11.375-1.542     C2.417,129.156,0,132.927,0,137.083v268.25C0,428.865,19.135,448,42.667,448h426.667C492.865,448,512,428.865,512,405.333     v-268.25C512,132.927,509.583,129.146,505.813,127.406z"
                      fill="#fa6755"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
            <span>
              <b>Эл. Почта: </b>
              <a href="mailto:info@ajax.group">info@ajax.group</a>
            </span>
          </div>
        </div>
        <div className="address_wrapper">
          <h3>Схема проезда</h3>
          <iframe
            src="https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=false&amp;um=constructor%3A9f6feb6e5e5f4be5719df080f10dc15841bac00c6e90db47b97c77a047364038"
            frameBorder="0"
            allowFullScreen
            width="100%"
            height="300px"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
