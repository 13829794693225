import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  img: string;
  title: string;
  url: string;
}

export const CarouselSlide: FC<Props> = ({ img, title, url }) => {
  return (
    <article className="carousel_slide">
      <Link to={url}>
        <img src={img} alt={title} />
      </Link>
      {/*  <button className="slide_more_button">Подробнее</button> */}
      <section>
        <div
          dangerouslySetInnerHTML={{ __html: title }}
          style={{ whiteSpace: "pre-line" }}
        ></div>
      </section>
    </article>
  );
};
