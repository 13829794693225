import "./404.scss";

export const NotFound = () => {
  return (
    <div className="not_found_page">
      <div className="text_wrapper">
        <h1>404</h1>
        <h2>Такой страницы не существует или она в разработке</h2>
      </div>
    </div>
  );
};
