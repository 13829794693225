import { useSelector } from "react-redux";
import { useActions } from "../../../hooks/useActions";
import "./CartPage.scss";
import { RootState } from "../../../store/store";
import { useEffect, useRef, useState } from "react";
import { CartItemInfo } from "../../../store/reducers/cartSlice";
import { CartItem } from "./CartItem";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../../config";

export const Cart = () => {
  const { setCartState } = useActions();

  const vendorCodeInputRef = useRef<HTMLInputElement>(null);

  const [showConfirmationButton, setShowConfirmationButton] = useState(false);

  const [showRemoveAllConfirmationButton, setShowRemoveAllConfirmationButton] =
    useState(false);

  const cartState = useSelector(
    (state: RootState) => state.cartReducer.itemsArray
  );

  const [showVendorCodeError, setVendorCodeError] = useState(false);

  const [selectedItems, setSelectedItems] = useState<CartItemInfo[]>([]);

  const [pendingItem, setPendingItem] = useState<CartItemInfo | null>(null);

  const [globalSelected, setGlobalSelected] = useState<boolean>(false);

  /*   useEffect(() => {


  }, [selectedItems]); */

  console.log(cartState);

  const getItemByVendorCode = async (vendorCode: string) => {
    const { data } = await axios.get(
      serverUrl + "item/vendor-code/" + vendorCode
    );
    console.log(data);
    if (!data.item.length) {
      setVendorCodeError(true);
      return;
    }
    if (
      cartState.find((el, i) => {
        return el.item_key == data.item[0].item_key;
      })
    ) {
      const foundItemIndex = cartState.findIndex(
        (e) => e.item_key === data.item[0]?.item_key
      );
      setCartState(
        cartState.map((e, i) => {
          if (i == foundItemIndex) {
            return { ...e, cartItemsCount: e.cartItemsCount + 1 };
          }
          return e;
        })
      );
      setVendorCodeError(false);
    } else {
      setCartState([{ ...data.item[0], cartItemsCount: 1 }, ...cartState]);
      setVendorCodeError(false);
    }
  };

  const handleQuickVendorCodeAdd = (vendorCode: string) => {
    getItemByVendorCode(vendorCode);
    if (vendorCodeInputRef.current) {
      vendorCodeInputRef.current.value = "";
    }
  };

  const handleSelectAll = () => {
    setSelectedItems(cartState);
  };

  const handleRemoveSelectedItems = () => {
    setCartState(
      cartState.filter((e, i) => {
        return !selectedItems.includes(e);
      })
    );
  };

  const handleUnselectAll = () => {
    setSelectedItems([]);
  };

  const handleRemoveItem = (item: CartItemInfo) => {
    setCartState(
      cartState.filter((e) => {
        return e.item_key != item.item_key;
      })
    );
  };

  const handleRemoveAll = () => {
    setCartState([]);
  };

  useEffect(() => {
    console.log(selectedItems);
  }, [selectedItems]);

  return (
    <>
      {" "}
      {showConfirmationButton ? (
        <div className="remove_confirmation_button">
          <h3>Точно удалить?</h3>
          <button
            onClick={() => {
              if (pendingItem) {
                handleRemoveItem(pendingItem);
                setShowConfirmationButton(false);
              }
            }}
          >
            Да
          </button>
          <div className="remove_item">
            {" "}
            <svg
              onClick={() => {
                setShowConfirmationButton(false);
              }}
              viewBox="0 -0.5 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                  fill="#000000"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
      ) : (
        <></>
      )}
      {showRemoveAllConfirmationButton ? (
        <div className="remove_confirmation_button">
          <h3>Точно удалить?</h3>
          <button
            onClick={() => {
              handleRemoveAll();
              setShowRemoveAllConfirmationButton(false);
            }}
          >
            Да
          </button>
          <div className="remove_item">
            {" "}
            <svg
              onClick={() => {
                setShowRemoveAllConfirmationButton(false);
              }}
              viewBox="0 -0.5 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                  fill="#000000"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="page_wrapper">
        <h1>Корзина</h1>
        <div className="cart_page_wrapper">
          <div className="cart_left">
            <div className="vendor_code_add_input_wrapper">
              <div className="input_wrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Быстрое добавление по артикулу"
                  ref={vendorCodeInputRef}
                  style={showVendorCodeError ? { border: "1px red solid" } : {}}
                />
                {showVendorCodeError ? <p>Введите верный артикул</p> : <></>}
              </div>
              <button
                onClick={() => {
                  if (vendorCodeInputRef.current) {
                    handleQuickVendorCodeAdd(vendorCodeInputRef.current?.value);
                  }
                }}
              >
                Добавить
              </button>
            </div>
            <div className="cart_delete_buttons_wrapper">
              <input
                type="checkbox"
                name=""
                id=""
                className="delete_buttons_checkbox"
                onChange={(e) => {
                  e.currentTarget.checked
                    ? handleSelectAll()
                    : handleUnselectAll();
                }}
              />
              <div
                className={
                  selectedItems.length > 0
                    ? "remove_selected-active"
                    : "remove_selected"
                }
                onClick={() => {
                  handleRemoveSelectedItems();
                }}
              >
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M1.5 3.75C1.08579 3.75 0.75 4.08579 0.75 4.5C0.75 4.91421 1.08579 5.25 1.5 5.25V3.75ZM22.5 5.25C22.9142 5.25 23.25 4.91421 23.25 4.5C23.25 4.08579 22.9142 3.75 22.5 3.75V5.25ZM1.5 5.25H22.5V3.75H1.5V5.25Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M9.75 1.5V0.75V1.5ZM8.25 3H7.5H8.25ZM7.5 4.5C7.5 4.91421 7.83579 5.25 8.25 5.25C8.66421 5.25 9 4.91421 9 4.5H7.5ZM15 4.5C15 4.91421 15.3358 5.25 15.75 5.25C16.1642 5.25 16.5 4.91421 16.5 4.5H15ZM15.75 3H16.5H15.75ZM14.25 0.75H9.75V2.25H14.25V0.75ZM9.75 0.75C9.15326 0.75 8.58097 0.987053 8.15901 1.40901L9.21967 2.46967C9.36032 2.32902 9.55109 2.25 9.75 2.25V0.75ZM8.15901 1.40901C7.73705 1.83097 7.5 2.40326 7.5 3H9C9 2.80109 9.07902 2.61032 9.21967 2.46967L8.15901 1.40901ZM7.5 3V4.5H9V3H7.5ZM16.5 4.5V3H15V4.5H16.5ZM16.5 3C16.5 2.40326 16.2629 1.83097 15.841 1.40901L14.7803 2.46967C14.921 2.61032 15 2.80109 15 3H16.5ZM15.841 1.40901C15.419 0.987053 14.8467 0.75 14.25 0.75V2.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L15.841 1.40901Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25H9ZM10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75H10.5ZM10.5 17.25V9.75H9V17.25H10.5Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25H13.5ZM15 9.75C15 9.33579 14.6642 9 14.25 9C13.8358 9 13.5 9.33579 13.5 9.75H15ZM15 17.25V9.75H13.5V17.25H15Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M18.865 21.124L18.1176 21.0617L18.1176 21.062L18.865 21.124ZM17.37 22.5L17.3701 21.75H17.37V22.5ZM6.631 22.5V21.75H6.63093L6.631 22.5ZM5.136 21.124L5.88343 21.062L5.88341 21.0617L5.136 21.124ZM4.49741 4.43769C4.46299 4.0249 4.10047 3.71818 3.68769 3.75259C3.2749 3.78701 2.96818 4.14953 3.00259 4.56231L4.49741 4.43769ZM20.9974 4.56227C21.0318 4.14949 20.7251 3.78698 20.3123 3.75259C19.8995 3.7182 19.537 4.02495 19.5026 4.43773L20.9974 4.56227ZM18.1176 21.062C18.102 21.2495 18.0165 21.4244 17.878 21.5518L18.8939 22.6555C19.3093 22.2732 19.5658 21.7486 19.6124 21.186L18.1176 21.062ZM17.878 21.5518C17.7396 21.6793 17.5583 21.75 17.3701 21.75L17.3699 23.25C17.9345 23.25 18.4785 23.0379 18.8939 22.6555L17.878 21.5518ZM17.37 21.75H6.631V23.25H17.37V21.75ZM6.63093 21.75C6.44274 21.75 6.26142 21.6793 6.12295 21.5518L5.10713 22.6555C5.52253 23.0379 6.06649 23.25 6.63107 23.25L6.63093 21.75ZM6.12295 21.5518C5.98449 21.4244 5.89899 21.2495 5.88343 21.062L4.38857 21.186C4.43524 21.7486 4.69172 22.2732 5.10713 22.6555L6.12295 21.5518ZM5.88341 21.0617L4.49741 4.43769L3.00259 4.56231L4.38859 21.1863L5.88341 21.0617ZM19.5026 4.43773L18.1176 21.0617L19.6124 21.1863L20.9974 4.56227L19.5026 4.43773Z"
                      fill="#808080"
                    ></path>{" "}
                  </g>
                </svg>
                <span>Удалить выбранное</span>
              </div>
              <div
                className="remove_all"
                onClick={() => setShowRemoveAllConfirmationButton(true)}
              >
                {" "}
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M1.5 3.75C1.08579 3.75 0.75 4.08579 0.75 4.5C0.75 4.91421 1.08579 5.25 1.5 5.25V3.75ZM22.5 5.25C22.9142 5.25 23.25 4.91421 23.25 4.5C23.25 4.08579 22.9142 3.75 22.5 3.75V5.25ZM1.5 5.25H22.5V3.75H1.5V5.25Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M9.75 1.5V0.75V1.5ZM8.25 3H7.5H8.25ZM7.5 4.5C7.5 4.91421 7.83579 5.25 8.25 5.25C8.66421 5.25 9 4.91421 9 4.5H7.5ZM15 4.5C15 4.91421 15.3358 5.25 15.75 5.25C16.1642 5.25 16.5 4.91421 16.5 4.5H15ZM15.75 3H16.5H15.75ZM14.25 0.75H9.75V2.25H14.25V0.75ZM9.75 0.75C9.15326 0.75 8.58097 0.987053 8.15901 1.40901L9.21967 2.46967C9.36032 2.32902 9.55109 2.25 9.75 2.25V0.75ZM8.15901 1.40901C7.73705 1.83097 7.5 2.40326 7.5 3H9C9 2.80109 9.07902 2.61032 9.21967 2.46967L8.15901 1.40901ZM7.5 3V4.5H9V3H7.5ZM16.5 4.5V3H15V4.5H16.5ZM16.5 3C16.5 2.40326 16.2629 1.83097 15.841 1.40901L14.7803 2.46967C14.921 2.61032 15 2.80109 15 3H16.5ZM15.841 1.40901C15.419 0.987053 14.8467 0.75 14.25 0.75V2.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L15.841 1.40901Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25H9ZM10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75H10.5ZM10.5 17.25V9.75H9V17.25H10.5Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25H13.5ZM15 9.75C15 9.33579 14.6642 9 14.25 9C13.8358 9 13.5 9.33579 13.5 9.75H15ZM15 17.25V9.75H13.5V17.25H15Z"
                      fill="#808080"
                    ></path>{" "}
                    <path
                      d="M18.865 21.124L18.1176 21.0617L18.1176 21.062L18.865 21.124ZM17.37 22.5L17.3701 21.75H17.37V22.5ZM6.631 22.5V21.75H6.63093L6.631 22.5ZM5.136 21.124L5.88343 21.062L5.88341 21.0617L5.136 21.124ZM4.49741 4.43769C4.46299 4.0249 4.10047 3.71818 3.68769 3.75259C3.2749 3.78701 2.96818 4.14953 3.00259 4.56231L4.49741 4.43769ZM20.9974 4.56227C21.0318 4.14949 20.7251 3.78698 20.3123 3.75259C19.8995 3.7182 19.537 4.02495 19.5026 4.43773L20.9974 4.56227ZM18.1176 21.062C18.102 21.2495 18.0165 21.4244 17.878 21.5518L18.8939 22.6555C19.3093 22.2732 19.5658 21.7486 19.6124 21.186L18.1176 21.062ZM17.878 21.5518C17.7396 21.6793 17.5583 21.75 17.3701 21.75L17.3699 23.25C17.9345 23.25 18.4785 23.0379 18.8939 22.6555L17.878 21.5518ZM17.37 21.75H6.631V23.25H17.37V21.75ZM6.63093 21.75C6.44274 21.75 6.26142 21.6793 6.12295 21.5518L5.10713 22.6555C5.52253 23.0379 6.06649 23.25 6.63107 23.25L6.63093 21.75ZM6.12295 21.5518C5.98449 21.4244 5.89899 21.2495 5.88343 21.062L4.38857 21.186C4.43524 21.7486 4.69172 22.2732 5.10713 22.6555L6.12295 21.5518ZM5.88341 21.0617L4.49741 4.43769L3.00259 4.56231L4.38859 21.1863L5.88341 21.0617ZM19.5026 4.43773L18.1176 21.0617L19.6124 21.1863L20.9974 4.56227L19.5026 4.43773Z"
                      fill="#808080"
                    ></path>{" "}
                  </g>
                </svg>
                <span>Удалить все</span>
              </div>
            </div>
            <div className="cart_items">
              {cartState.map((e, i) => (
                <CartItem
                  selectedItems={selectedItems}
                  globalSelected={globalSelected}
                  setSelectedItems={setSelectedItems}
                  setShowConfirmationButton={setShowConfirmationButton}
                  setPendingItem={setPendingItem}
                  item={e}
                  index={i}
                />
              ))}
            </div>
          </div>
          <div className="cart_right">
            <div className="cost_wrapper">
              <div className="total_cost">
                <h2>
                  Товаров(
                  {cartState.reduce((prev, curr) => {
                    return prev + curr.cartItemsCount;
                  }, 0)}
                  ) на сумму:{" "}
                </h2>
                <span>
                  {cartState.reduce((prev, curr) => {
                    return prev + curr.cartItemsCount * curr.item_cost;
                  }, 0)}
                  ₽
                </span>
              </div>
              <div className="cart_right_underline"></div>
              <h2>Способ доставки</h2>
              <div className="total_cost">
                <h2>Доставка:</h2>
                <span>Бесплатно</span>
              </div>
              <div className="total_cost">
                <h2>Самовывоз:</h2>
                <span>Бесплатно</span>
              </div>
              <div className="cart_right_underline"></div>
              <div className="total_heading_wrapper">
                <div className="total_heading">
                  <div className="header_wrapper">
                    <h1>Итого</h1>
                    {/* <svg
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.877075 7.49972C0.877075 3.84204 3.84222 0.876892 7.49991 0.876892C11.1576 0.876892 14.1227 3.84204 14.1227 7.49972C14.1227 11.1574 11.1576 14.1226 7.49991 14.1226C3.84222 14.1226 0.877075 11.1574 0.877075 7.49972ZM7.49991 1.82689C4.36689 1.82689 1.82708 4.36671 1.82708 7.49972C1.82708 10.6327 4.36689 13.1726 7.49991 13.1726C10.6329 13.1726 13.1727 10.6327 13.1727 7.49972C13.1727 4.36671 10.6329 1.82689 7.49991 1.82689ZM8.24993 10.5C8.24993 10.9142 7.91414 11.25 7.49993 11.25C7.08571 11.25 6.74993 10.9142 6.74993 10.5C6.74993 10.0858 7.08571 9.75 7.49993 9.75C7.91414 9.75 8.24993 10.0858 8.24993 10.5ZM6.05003 6.25C6.05003 5.57211 6.63511 4.925 7.50003 4.925C8.36496 4.925 8.95003 5.57211 8.95003 6.25C8.95003 6.74118 8.68002 6.99212 8.21447 7.27494C8.16251 7.30651 8.10258 7.34131 8.03847 7.37854L8.03841 7.37858C7.85521 7.48497 7.63788 7.61119 7.47449 7.73849C7.23214 7.92732 6.95003 8.23198 6.95003 8.7C6.95004 9.00376 7.19628 9.25 7.50004 9.25C7.8024 9.25 8.04778 9.00601 8.05002 8.70417L8.05056 8.7033C8.05924 8.6896 8.08493 8.65735 8.15058 8.6062C8.25207 8.52712 8.36508 8.46163 8.51567 8.37436L8.51571 8.37433C8.59422 8.32883 8.68296 8.27741 8.78559 8.21506C9.32004 7.89038 10.05 7.35382 10.05 6.25C10.05 4.92789 8.93511 3.825 7.50003 3.825C6.06496 3.825 4.95003 4.92789 4.95003 6.25C4.95003 6.55376 5.19628 6.8 5.50003 6.8C5.80379 6.8 6.05003 6.55376 6.05003 6.25Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg> */}
                    <div className="info_additional"></div>
                    <div className="info_additional_step"></div>
                  </div>
                  <span>С учетом доставки</span>
                </div>
                <span>
                  {cartState.reduce((prev, curr) => {
                    return prev + curr.cartItemsCount * curr.item_cost;
                  }, 0)}
                  ₽
                </span>
              </div>
            </div>
            <Link to={"/checkout"}>
              <div>Оформить заказ</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
