import "./CertificatesPage.scss";
import "../../Pages/OrderPage/OrderPage.scss";
import { Link } from "react-router-dom";
import { Certificateitem } from "./CertificateItem";

export const CertificatesPage = () => {
  return (
    <div className="order_page_wrapper">
      <h1 className="page_heading">СЕРТИФИКАТЫ И ЛИЦЕНЗИИ</h1>
      <Certificateitem
        title="Лицензия МЧС от 30.12.2020 года"
        assets={[
          {
            img: "/assets/certificates/s01-212x300.webp",
            file: "/assets/certificates/s01-212x300.webp",
          },
          {
            img: "/assets/certificates/s02-212x300.webp",
            file: "/assets/certificates/s02-212x300.webp",
          },
        ]}
      ></Certificateitem>
      <Certificateitem
        title="Выписка ассоциации проектировщиков от 07.10.2020 года"
        assets={[
          {
            img: "/assets/certificates/s03-206x300.webp",
            file: "/assets/certificates/s03-206x300.webp",
          },
          {
            img: "/assets/certificates/s04-207x300.webp",
            file: "/assets/certificates/s04-207x300.webp",
          },
        ]}
      ></Certificateitem>
      <Certificateitem
        title="Выписка СРО от 17.02.2021 года"
        assets={[
          {
            img: "/assets/certificates/s05-204x300.webp",
            file: "/assets/certificates/s05-204x300.webp",
          },
          {
            img: "/assets/certificates/s06-205x300.webp",
            file: "/assets/certificates/s06-205x300.webp",
          },
        ]}
      ></Certificateitem>
      <Certificateitem
        title="Свидетельство Geberiy"
        assets={[
          {
            img: "/assets/certificates/s07-232x300.webp",
            file: "/assets/certificates/s07-232x300.webp",
          },
          {
            img: "/assets/certificates/s08-232x300.webp",
            file: "/assets/certificates/s08-232x300.webp",
          },
        ]}
      ></Certificateitem>
      <Certificateitem
        title="Сертификат соответствия"
        assets={[
          {
            img: "/assets/certificates/s-01.png",
            file: "/assets/certificates/s-01.pdf",
          },
        ]}
      ></Certificateitem>
      <Certificateitem
        title="Технический паспорт изделия"
        assets={[
          {
            img: "/assets/certificates/p-01.png",
            file: "/assets/certificates/p-01.pdf",
          },
          {
            img: "/assets/certificates/p-02.png",
            file: "/assets/certificates/p-01.pdf",
          },
        ]}
      ></Certificateitem>
      <Certificateitem
        title="Декларация соответствия"
        assets={[
          {
            img: "/assets/certificates/ds-01.png",
            file: "/assets/certificates/ds-01.pdf",
          },
        ]}
      ></Certificateitem>
    </div>
  );
};
