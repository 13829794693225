import { serverUrl } from "../../../config";
import useFetch from "../../../hooks/useFetch";
import { Partner } from "../../../types";
import "./PartnersPage.scss";

export const PartnersPage = () => {
  const { data, error } = useFetch<Partner[]>(serverUrl + "partners");

  console.log(data);

  return (
    <div className="partners_page_wrapper">
      <h1>Наши партнеры</h1>
      <div className="partners_grid">
        {data ? (
          data.map((e, i) => (
            <div className="partners_item">
              <div className="image_wrapper">
                <a href={e.url}>
                  <img src={`/assets/partners/${e.img}`} alt={e.title} />
                </a>
              </div>
              <p className="partners_description">{e.description}</p>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
