import { Link } from "react-router-dom";
import { serverUrl } from "../../../config";
import useFetch from "../../../hooks/useFetch";
import { Partner } from "../../../types";
import { TitleUnderline } from "../TitileUndeline";
import "./Partners.scss";
import { PartnersItem } from "./PartnersItem";
import { useEffect, useRef, useState } from "react";

export const Partners = () => {
  const { data, error } = useFetch<Partner[]>(serverUrl + "partners");
  const [sliderStyles, setSliderStyles] = useState<React.CSSProperties>({});
  const sliderRef = useRef<HTMLDivElement>(null);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const slidesRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<any>(null);

  const startSliderInterval = () => {
    console.log(slideIndex);
    setSlideIndex((prev) => {
      if (prev + 1 < (slidesRef.current as HTMLDivElement).children.length) {
        return prev + 1;
      } else return prev;
    });
  };

  useEffect(() => {
    intervalRef.current = setInterval(startSliderInterval, 5000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (data) {
      setSliderStyles({
        transform: `translateX(${(100 / data.length) * -slideIndex}%)`,
        transition: `transform ${0.5}s`,
      });
      console.log(slideIndex);
    }
  }, [slideIndex]);

  return (
    <article className="partners_wrapper">
      <Link to={"/partners"}>
        <h1>ПАРТНЕРЫ</h1>
      </Link>
      <TitleUnderline></TitleUnderline>
      <section className="partners_container_wrapper">
        <div
          className="partners_container"
          ref={slidesRef}
          style={sliderStyles}
        >
          {data ? (
            data.map((e, i) => (
              <PartnersItem
                itemImg={e.banner_img}
                itemTitle={e.title}
                itemUrl={e.url}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </section>
    </article>
  );
};
