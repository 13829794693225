import { useLayoutEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { MerchandiseItemInfo } from "../../../../types";
import { MerсhandiseCard } from "../../../Home/MerchandiseCards/MerchandiseCard";
import { TitleUnderline } from "../../../Home/TitileUndeline";
import { CarouselIndicator } from "../../../Carousel/CarouselIndicator";
import { Link } from "react-router-dom";
import { serverUrl } from "../../../../config";

export const CouplingMerchandiseCards = () => {
  const { data, error, state } = useFetch<MerchandiseItemInfo[]>(
    serverUrl + "merchandise"
  );

  const itemsTemplateArray = JSON.parse(
    localStorage.getItem("merchandise_items_count") || "[]"
  );

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  const [sliderIndex, setSliderIndex] = useState<number>(0);

  return (
    <div
      className="merchandise_cards_wrapper"
      style={{
        width: "300px",
        margin: "0 auto",
        marginTop: "100px",
        marginBottom: "100px",
      }}
    >
      <div className="merchandise_cards_container">
        <div className="merchandise_cards_slider_wrapper">
          <button
            className="merchandise_cards_slider_button left"
            onClick={() => {
              if (sliderIndex - 1 > -1) {
                setSliderIndex(sliderIndex - 1);
              }
            }}
          >
            ‹
          </button>
          <div
            className="merchandise_cards_slider"
            style={{
              transform: data
                ? `translateX(-${(100 / data.length) * sliderIndex}%)`
                : "",
            }}
          >
            {data
              ? data.map((e, i) => (
                  <MerсhandiseCard info={{ ...e, cartItemsCount: 0 }} key={i} />
                ))
              : itemsTemplateArray.map((e: "", i: number) => (
                  <MerсhandiseCard key={i} />
                ))}
          </div>
          <button
            className="merchandise_cards_slider_button right"
            onClick={() => {
              if (data && sliderIndex + 1 < data?.length) {
                setSliderIndex(sliderIndex + 1);
              }
            }}
          >
            ›
          </button>
          <CarouselIndicator
            setSlideIndex={setSliderIndex}
            slideIndex={sliderIndex}
            slides={data || itemsTemplateArray}
          />
          <Link className="show-all_button" to="/shop">
            Показать все
          </Link>
        </div>
      </div>
    </div>
  );
};
