import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  imageUrl: string;
  description: string;
  slideLink: string;
}

export const CompletedContractsSlide: FC<Props> = ({
  imageUrl,
  description,
  slideLink,
}) => {
  return (
    <article className="completed_contracts_slide">
      <section>
        <div>
          <Link to={`completed-contracts/${slideLink}`}>
            <span>{description}</span>
          </Link>
        </div>
      </section>
      <Link to={`completed-contracts/${slideLink}`}>
        <img src={`assets/banners/${imageUrl}`} alt="" />
      </Link>
      {/*  <button className="slide_more_button">Подробнее</button> */}
    </article>
  );
};
