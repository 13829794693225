import useFetch from "../../../../hooks/useFetch";
import "./CompletedContractPage.scss";

import { Contract } from "../../../../types";
import { serverUrl } from "../../../../config";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";

export const CompletedContractPage = () => {
  const url = useLocation();

  const navigate = useNavigate();

  console.log(url.pathname.split("/")[2]);

  const { data, error } = useFetch<Contract[]>(
    serverUrl + `contracts/${url.pathname.split("/")[2]}`
  );

  useEffect(() => {
    console.log(data);
    if (data && data.length == 0) {
      console.log(data);
      navigate("/404");
      return;
    }
  }, [data]);

  return (
    <div className="completed_contract_wrapper">
      <div className="completed_contract">
        {data ? (
          <>
            <h3 className="contract_heading">{data[0].title}</h3>
            <div className="contract_wrapper">
              <div className="contract_info">
                <h4 className="contract_customer">
                  <b>Заказчик:</b> {data[0].customer}
                </h4>
                {data[0].contract_details ? (
                  <div className="contract_details">
                    {data[0].contract_details.details.map((e, i) => (
                      <ul>
                        <li>
                          <b>Местоположение:</b> {e.location}
                        </li>
                        <li>
                          <b>Площадь:</b> {e.area} м<sup>2</sup>
                        </li>
                        {e.description ? (
                          <li>
                            <b>Выполненные работы:</b> {e.description}
                          </li>
                        ) : (
                          <></>
                        )}
                        <li>
                          <b>Период работы:</b>{" "}
                          {e.periods.map((period, i) => (
                            <>
                              <span>
                                {period.contract_period_start}-
                                {period.contract_period_end}
                              </span>
                              <br />
                            </>
                          ))}
                        </li>
                      </ul>
                    ))}
                  </div>
                ) : (
                  <ul>
                    <li>
                      <b>Местоположение:</b> {data[0].location}
                    </li>
                    <li>
                      <b>Площадь:</b> {data[0].area} м<sup>2</sup>
                    </li>
                    <li>
                      <b>Выполненные работы:</b> {data[0].operation_description}
                    </li>
                    <li>
                      <b>Период работы:</b> {data[0].contract_period_start}-
                      {data[0].contract_period_end}
                    </li>
                  </ul>
                )}
              </div>
              <div className="contract_image">
                {data[0].images.images.map((e, i) => (
                  <img src={`/assets/contracts/${e}`} alt={data[0].title} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
