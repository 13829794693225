export const PrivacyPolicy = () => {
  return (
    <div className="page_wrapper">
      <h1>Политика конфиденциальности</h1>
      <div
        id="content"
        className="text_wrapper"
        role="main"
        style={{ marginBottom: "30px" }}
      >
        <article
          id="post-3"
          className="post-3 page type-page status-publish hentry"
        >
          <div className="entry-content">
            <h4 className="wp-block-heading">1. Общие положения</h4>
            <p>
              1.1 ООО «АЯКС» (далее по тексту – Оператор) ставит соблюдение прав
              и свобод граждан одним из важнейших условий осуществления своей
              деятельности.
              <br />
              1.2 Политика Оператора в отношении обработки персональных данных
              (далее по тексту — Политика) применяется ко всей информации,
              которую Оператор может получить о посетителях веб-сайта
              ajax-group.ru. Персональные данные обрабатывается в соответствии с
              ФЗ «О персональных данных» № 152-ФЗ.
            </p>
            <h4 className="wp-block-heading">
              2. Основные понятия, используемые в Политике:
            </h4>
            <p>
              2.1 Веб-сайт — совокупность графических и информационных
              материалов, а также программ для ЭВМ и баз данных, обеспечивающих
              их доступность в сети интернет по сетевому адресу ajax-group.ru;
              <br />
              2.2 Пользователь – любой посетитель веб-сайта ajax-group.ru;
              <br />
              2.3 Персональные данные – любая информация, относящаяся к
              Пользователю веб-сайта ajax-group.ru;
              <br />
              2.4 Обработка персональных данных — любое действие с персональными
              данными, совершаемые с использованием ЭВМ, равно как и без их
              использования;
              <br />
              2.5 Обезличивание персональных данных – действия, результатом
              которых является невозможность без использования дополнительной
              информации определить принадлежность персональных данных
              конкретному Пользователю или лицу;
              <br />
              2.6 Распространение персональных данных – любые действия,
              результатом которых является раскрытие персональных данных
              неопределенному кругу лиц;
              <br />
              2.7 Предоставление персональных данных – любые действия,
              результатом которых является раскрытие персональных данных
              определенному кругу лиц;
              <br />
              2.8 Уничтожение персональных данных – любые действия, результатом
              которых является безвозвратное уничтожение персональных на ЭВМ или
              любых других носителях.
            </p>
            <h4 className="wp-block-heading">
              3. Оператор может обрабатывать следующие персональные данные
              Пользователя:
            </h4>
            <p>
              3.1 Список персональных данных, которые обрабатывает оператор:
              фамилия, имя, отчество, номер телефона, адрес электронной почты.
              <br />
              3.2. Кроме того, на сайте происходит сбор и обработка обезличенных
              данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
              интернет-статистики (Яндекс Метрика, Гугл Аналитика и других).
            </p>
            <h4 className="wp-block-heading">
              4. Цели обработки персональных данных
            </h4>
            <p>
              4.1 Персональные данные пользователя — фамилия, имя, отчество,
              номер телефона, адрес электронной почты — обрабатываются со
              следующей целью: уточнение деталей заказа. Оператор имеет право
              направлять Пользователю уведомления о новых продуктах и услугах,
              специальных предложениях и различных событиях. Пользователь всегда
              может отказаться от получения информационных сообщений, направив
              Оператору письмо на адрес example@advegital.com
              <br />
              4.2 Обезличенные данные Пользователей, собираемые с помощью
              сервисов интернет-статистики, служат для сбора информации о
              действиях Пользователей на сайте, улучшения качества сайта и его
              содержания.
            </p>
            <h4 className="wp-block-heading">
              5. Правовые основания обработки персональных данных
            </h4>
            <p>
              5.1 Оператор обрабатывает персональные данные Пользователя только
              в случае их отправки Пользователем через формы, расположенные на
              веб-сайте ajax-group.ru. Отправляя свои персональные данные
              Оператору, Пользователь выражает свое согласие с данной Политикой.
              <br />
              5.2 Оператор обрабатывает обезличенные данные о Пользователе в
              случае, если Пользователь разрешил это в настройках браузера
              (включено сохранение файлов «cookie» и использование технологии
              JavaScript).
            </p>
            <h4 className="wp-block-heading">
              6. Порядок сбора, хранения, передачи и других видов обработки
              персональных данных
            </h4>
            <p>
              6.1 Оператор обеспечивает сохранность персональных данных и
              принимает все возможные меры, исключающие доступ к персональным
              данным неуполномоченных лиц.
              <br />
              6.2 Персональные данные Пользователя никогда, ни при каких
              условиях не будут переданы третьим лицам, за исключением случаев,
              связанных с исполнением действующего законодательства.
              <br />
              6.3. В случае выявления неточностей в персональных данных,
              Пользователь может актуализировать их, направив Оператору
              уведомление с помощью электронной почты на электронный адрес
              Оператора example@advegital.com, либо на почтовый адрес Оператора
              141001, Московская область, г. Мытищи, ул. Силикатная 39Ж, с
              пометкой «Актуализация персональных данных».
              <br />
              6.3 Срок обработки персональных данных является неограниченным.
              Пользователь может в любой момент отозвать свое согласие на
              обработку персональных данных, направив Оператору уведомление с
              помощью электронной почты на электронный адрес Оператора
              example@advegital.com, либо на почтовый адрес Оператора 141001,
              Московская область, г. Мытищи, ул. Силикатная 39Ж, с пометкой
              «Отзыв согласия на обработку персональных данных».
            </p>
            <h4 className="wp-block-heading">7. Заключительные положения</h4>
            <p>
              7.1. Пользователь может получить любые разъяснения по интересующим
              вопросам, касающимся обработки его персональных данных,
              обратившись к Оператору с помощью электронной почты
              example@advegital.com, либо на почтовый адрес Оператора 141001,
              Московская область, г. Мытищи, ул. Силикатная 39Ж.
              <br />
              7.2. В данном документе будут отражены любые изменения политики
              обработки персональных данных Оператором. В случае существенных
              изменений Пользователю может быть выслана информация на указанный
              им электронный адрес.
            </p>
            <div className="inner-container"></div>
            {/* .inner-container */}
          </div>
          {/* .entry-content */}
        </article>
        {/* #post-## */}{" "}
      </div>{" "}
      {/* #content */}
    </div>
  );
};
