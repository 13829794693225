import "./ServiceDescriptionPage.scss";

export const Operations = () => {
  return (
    <div className="service_description_page_wrapper">
      <h1>ЭКСПЛУАТАЦИЯ</h1>
      <div className="service_description_content">
        <img src="/assets/services/ekspluatatciia.jpg" alt="ЭКСПЛУАТАЦИЯ" />
        <div className="service_description">
          <h2>
            Техническое обслуживание и эксплуатация противопожарных систем.
          </h2>
          <p>
            Согласно требованиям Правил противопожарного режима в РФ №390 от
            25.04.2012г. органами МЧС РФ проверяется наличие заключенных
            договоров на техническое обслуживание пожарных систем. Закон
            обязывает заключить их только с подрядчиками, имеющими лицензию на
            данный вид деятельности. Наша компания имеет лицензию на ТО СПЗ, что
            удовлетворяет требованиям противопожарного режима. Без
            своевременного техобслуживания противопожарных систем есть риск
            несрабатывания отдельных ее элементов при возникновении пожара. Это
            может привести к материальному ущербу, угрозе жизни и здоровью
            людей. Наша организация специализируется на техобслуживании
            вышеперечисленных систем в Москве и за ее пределами.
          </p>
        </div>
      </div>
    </div>
  );
};
