import { FC, useState, useEffect } from "react";

interface Props {
  incrementableValueMax: number;
  description: string;
}

export const AdvantagesCard: FC<Props> = ({
  incrementableValueMax,
  description,
}) => {
  const [incrementableValue, setIncrementableValue] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (incrementableValue < incrementableValueMax) {
        setIncrementableValue((e) => e + 1);
      }
    }, 800 / (incrementableValueMax - incrementableValue));
  }, [incrementableValue]);

  return (
    <div className="advantages_item">
      <h1>{incrementableValue}</h1>
      <div className="advantages_underline"></div>
      <h2>{description}</h2>
    </div>
  );
};
