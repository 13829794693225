import { FC, useState } from "react";
import { ArrowButton } from "../Header/NavigationFull/ArrowButton";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  links?: {
    title: string;
    url: string;
    isNested?: boolean;
    itemsNested?: { url: string; title: string }[];
  }[];
  url?: string;
}

const BurgerSection: FC<Props> = ({ title, links, url }) => {
  const [displayItems, setDisplayItems] = useState<boolean>(false);
  const [displayNestedItems, setDisplayNestedItems] = useState<boolean>(false);
  return (
    <ul>
      <span
        className="burger_menu_link"
        onClick={() => setDisplayItems((e) => !e)}
      >
        {title}{" "}
        {links ? (
          <svg
            style={{ transform: `rotate(${180 * +displayItems}deg)` }}
            fill="#000000"
            height="10px"
            width="10px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 330 330"
            xmlSpace="preserve"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                id="XMLID_225_"
                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
              ></path>{" "}
            </g>
          </svg>
        ) : (
          <></>
        )}
      </span>
      {displayItems ? (
        links?.map((e, i) =>
          e.isNested ? (
            <>
              <ul key={i}>
                <span
                  className="burger_menu_link"
                  onClick={() => setDisplayNestedItems((e) => !e)}
                >
                  {e.title}{" "}
                  <svg
                    style={{
                      transform: `rotate(${180 * +displayNestedItems}deg)`,
                    }}
                    fill="#000000"
                    height="10px"
                    width="10px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 330 330"
                    xmlSpace="preserve"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      ></path>{" "}
                    </g>
                  </svg>
                </span>
                {displayNestedItems &&
                  e.itemsNested!.map((e, i) => (
                    <li key={i}>
                      <Link to={e.url}>— {e.title}</Link>
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <li key={i}>
              <Link to={e.url}>{e.title}</Link>
            </li>
          )
        )
      ) : (
        <></>
      )}
    </ul>
  );
};

export default BurgerSection;
