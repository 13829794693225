import { FC } from "react";
import { Type } from "typescript";

interface Props {
  slideIndex: number;
  setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
  slides: any[];
}

export const CarouselIndicator: FC<Props> = ({
  slideIndex,
  slides,
  setSlideIndex,
}) => {
  console.log(slides);
  return (
    <div className="carousel_indicator">
      {slides?.map((e, i) => (
        <div
          key={i}
          className={
            i == slideIndex ? "carousel_radio-active" : "carousel_radio"
          }
          onClick={() => {
            setSlideIndex(i);
          }}
        ></div>
      ))}
    </div>
  );
};
