import { Link } from "react-router-dom";
import "../../Page.scss";
import "./DesignPage.scss";

export const DesignPage = () => {
  return (
    <div className="page_wrapper">
      <h1 className="page_heading">Проектирование</h1>
      <div className="design_services_grid">
        <div className="grid_item">
          <Link to="appz">АППЗ</Link>
        </div>
        <div className="grid_item">
          <Link to="aps">АПC</Link>
        </div>
        <div className="grid_item">
          <Link to="aupt">АУПТ</Link>
        </div>
        <div className="grid_item">
          <Link to="vpv">ВПВ</Link>
        </div>
        <div className="grid_item">
          <Link to="soue">СОУЭ</Link>
        </div>
      </div>
    </div>
  );
};
