import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  sectionTitle: string;
  linkItems: {
    title: string;
    url: string;
    isNested?: boolean;
    itemsNested?: { url: string; title: string }[];
  }[];
  url: string;
}

export const FooterLinks: FC<Props> = ({ linkItems, sectionTitle, url }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const [displayLinks, setDisplayLinks] = useState(false);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);
  return (
    <>
      {window.innerWidth > 900 ? (
        <aside id="nav_menu-2" className="footer_section_links">
          <div className="title-outer">
            <Link to={url || ""}>
              <h3 className="widget-title">{sectionTitle.toUpperCase()}</h3>
            </Link>
            <div className="widget_underline"></div>
          </div>
          <ul className="links_list">
            {linkItems.map((e, i) =>
              e.isNested ? (
                <div key={i}>
                  <ul key={i}>
                    {e.title}
                    {e.itemsNested!.map((e, i) => (
                      <li key={i} className="nested_list">
                        <Link to={e.url}>{e.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <li key={i}>
                  <Link to={e.url}>{e.title}</Link>
                </li>
              )
            )}
          </ul>
        </aside>
      ) : (
        <aside id="nav_menu-2" className="footer_section_links_clipped">
          <div
            className="title_outer-clipped"
            onClick={() => {
              setDisplayLinks((e) => !e);
            }}
          >
            <Link to={url || ""}>
              <h3 className="widget-title">{sectionTitle.toUpperCase()}</h3>
            </Link>
            <svg
              fill="#000000"
              height="200px"
              width="200px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 330 330"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  id="XMLID_225_"
                  d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                ></path>
              </g>
            </svg>
          </div>
          <ul className="links_list_clipped">
            {displayLinks ? (
              linkItems.map((e, i) =>
                e.isNested ? (
                  <>
                    <ul key={i} className="nested_list">
                      {e.title}
                      {e.itemsNested!.map((e, i) => (
                        <li key={i} className="nested_list">
                          <Link to={e.url}>{e.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <li key={i}>
                    <Link to={e.url}>{e.title}</Link>
                  </li>
                )
              )
            ) : (
              <></>
            )}
          </ul>
        </aside>
      )}
    </>
  );
};
